import React from 'react'

export interface IDivProps extends React.HTMLAttributes<HTMLDivElement> {
  if?: boolean
  show?: boolean
  hidden?: boolean
  active?: boolean
}

export default function Div(props: IDivProps) {
  const getClasses = () => {
    let classes = props.className
    if (props.hidden !== undefined && !props.hidden) {
      classes += ' hidden'
    } else if (props.show !== undefined && !props.show) {
      classes += ' invisible'
    } else if (props.active) {
      classes += ' active'
    }
    return classes
  }

  if (props.if === undefined || props.if) {
    return <div id={props.id} onClick={props.onClick} className={getClasses()}>{props.children}</div>
  }

  return <React.Fragment />
}