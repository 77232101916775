import React, { useState, useEffect, useContext } from 'react'
import { Dialog, Switch, FormControlLabel, withStyles } from '@material-ui/core'
import swal from 'sweetalert';
import SidebarMenu from '../components/utils/SidebarMenu'
import Header from '../components/utils/Header'
import ProfilePicture from '../components/utils/ProfilePicture'
import BackdropLoading from '../components/utils/BackdropLoading'
import Div from '../components/utils/Div'
import PageControl from '../components/utils/PageControl';
import UserManager, { TemporaryUser, UserList } from '../managers/UserManager'
import { AppContext } from '../services/AppContextService';
import Constants, { MENU_ITEMS, SETTINGS_COMPONENTS, EXCEPTION_ERRORS, USER_PERMISSIONS } from '../Constants'
import './Settings.scss'

import petrosIcon from '../assets/images/Petra_Icon.svg'

interface IUserComponentProps {
  activeComponent: string
  openCreateUser: boolean
  updateUserList: number
  setOpenCreateUser: (state: boolean) => void
  onCreateSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

class UserSettings {
  uid = ''
  name = ''
  profilePictureUrl = ''
  status = 1
}

export default function Settings() {
  const [activeComponent, setActiveComponent] = useState('')
  const [openCreateUser, setOpenCreateUser] = useState(false)
  const [updateUserList, setUpdateUserList] = useState(0)

  const getTitle = () => {
    if (activeComponent === SETTINGS_COMPONENTS.TIME_PETROS) {
      return 'Time Petros - Especialistas'
    } else if (activeComponent === SETTINGS_COMPONENTS.ADMIN) {
      return 'Administradores'
    }
    return 'Escolha uma categoria'
  }

  const showActiveComponent = () => {
    if (activeComponent === SETTINGS_COMPONENTS.TIME_PETROS || activeComponent === SETTINGS_COMPONENTS.ADMIN) {
      return <UserComponent activeComponent={activeComponent} openCreateUser={openCreateUser} updateUserList={updateUserList} setOpenCreateUser={setOpenCreateUser} onCreateSubmit={createUser} />
    }
    return ''
  }

  const createUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const name = document.getElementById('inp_name') as HTMLInputElement
    const email = document.getElementById('inp_email') as HTMLInputElement
    const password = document.getElementById('inp_password') as HTMLInputElement

    const user = new TemporaryUser()
    user.name = name.value
    user.email = email.value.toLowerCase()
    user.password = password.value

    if (activeComponent === SETTINGS_COMPONENTS.ADMIN) {
      user.permission = USER_PERMISSIONS.ADMIN
    }

    UserManager.createTemporaryUser(user, error => {
      setOpenCreateUser(false)
      if (error) {
        swal(Constants.getErrorMessage(error.code), '', 'error')
      } else {
        setUpdateUserList(updateUserList + 1)
        swal('Usuário criado com sucesso!', '', 'success')
      }
    })
  }

  return (
    <div className="container-fluid p-0">
      <SidebarMenu activeMenu={MENU_ITEMS.SETTINGS} />
      <Header>Configurações</Header>

      <div className="row settings">
        <main className="offset-1 p-0 col-10">
          <div className="header-title">{getTitle()}</div>
          <div className="content-container">
            <Div if={!activeComponent} className="card-list">
              <div className="card-item" onClick={() => setActiveComponent(SETTINGS_COMPONENTS.TIME_PETROS)}>
                <div className="actions"></div>
                <img src={petrosIcon} alt="petros icone" />
                <div className="card-title">Time Petros Especialistas</div>
              </div>
              <div className="card-item" onClick={() => setActiveComponent(SETTINGS_COMPONENTS.ADMIN)}>
                <div className="actions"></div>
                <span className="material-icons">admin_panel_settings</span>
                <div className="card-title">Administradores</div>
              </div>
            </Div>

            {showActiveComponent()}
          </div>
          <Div if={!!activeComponent} className="buttons">
            <button className="primary-button" onClick={() => setActiveComponent('')}>Voltar</button>
            <button className="primary-button" onClick={() => setOpenCreateUser(true)}>Novo Usuário</button>
          </Div>
        </main>
      </div>
    </div>
  )
}

function UserComponent(props: IUserComponentProps) {
  const context = useContext(AppContext)
  const pageLimit = 10
  const [users, setUsers] = useState([new UserSettings()])
  const [filteredUsers, setFilteredUsers] = useState([new UserSettings()])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const buildUserList = (userList: UserList, promises: Promise<any>[], usersResult: UserSettings[]) => {
    for (let uid in userList) {
      if (uid !== context.currentUser?.uid) {
        const user = { uid, name: userList[uid].name } as UserSettings
        promises.push(new Promise((resolve, reject) => {
          if (userList[uid].profilePicture) {
            UserManager.getUserPicture(userList[uid].profilePicture.fullPath, url => {
              user.profilePictureUrl = url
              user.status = userList[uid]._status
              usersResult.push(user)
              resolve()
            })
          } else {
            user.status = userList[uid]._status
            usersResult.push(user)
            resolve()
          }
        })
        )
      }
    }
  }

  const getAndOrderUsers = (promises: Promise<any>[], usersResult: UserSettings[]) => {
    Promise.all(promises).then(() => {
      setUsers(usersResult.sort((a, b) => {
        if (a.name > b.name)
          return 1
        if (a.name < b.name)
          return -1
        return 0
      }))
      setLoading(false)
    })
  }

  const loadUsers = () => {
    setLoading(true)

    if (props.activeComponent === SETTINGS_COMPONENTS.TIME_PETROS) {
      UserManager.getAllSpecialist(userList => {
        const usersResult: UserSettings[] = []
        const promises: Promise<any>[] = []

        buildUserList(userList, promises, usersResult)

        promises.push(new Promise((resolve, reject) => {
          UserManager.getAllTemporarySpecialist(userList => {
            for (let key in userList) {
              const user = { uid: key, name: userList[key].name } as UserSettings
              usersResult.push(user)
            }
            resolve()
          })
        }))

        getAndOrderUsers(promises, usersResult)
      })
    } else if (props.activeComponent === SETTINGS_COMPONENTS.ADMIN) {
      UserManager.getAllAdmin(userList => {
        const usersResult: UserSettings[] = []
        const promises: Promise<any>[] = []

        buildUserList(userList, promises, usersResult)

        promises.push(new Promise((resolve, reject) => {
          UserManager.getAllTemporaryAdmin(userList => {
            for (let key in userList) {
              const user = { uid: key, name: userList[key].name } as UserSettings
              usersResult.push(user)
            }
            resolve()
          })
        }))

        getAndOrderUsers(promises, usersResult)
      })
    }
  }

  const filterUsers = () => {
    const filteredList = users.slice(currentPage * pageLimit, currentPage + pageLimit)
    setFilteredUsers(filteredList)
  }

  const changeStatus = (uid: string, checked: boolean) => {
    setLoading(true)
    const status = checked ? 1 : 0
    UserManager.setStatus(uid, status, loadUsers, () => swal(EXCEPTION_ERRORS.SAVE_USER, '', 'danger'))
  }

  const removeTemporaryUser = (user: UserSettings) => {
    swal({
      title: `Tem certeza que deseja remover o usuário ${user.name}?`,
      icon: "warning",
      buttons: ["NÃO", "SIM"]
    }).then(value => {
      if (value) {
        setLoading(true)
        UserManager.removeTemporaryUser(user.uid, () => {
          loadUsers()
          swal('Usuário removido com sucesso!', '', 'success')
        })
      }
    })
  }

  useEffect(loadUsers, [props.updateUserList])
  useEffect(filterUsers, [users, currentPage])

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#B2BEC3',
      '&$checked': {
        color: '#F2835A',
      },
      '&$checked + $track': {
        backgroundColor: '#F2835A',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <React.Fragment>
      <PageControl pageLimit={pageLimit} listLength={users.length} currentPage={currentPage} onPageChange={setCurrentPage} />
      <div className="card-list">
        {filteredUsers.map(user =>
          <div key={user.uid} className={`card-item no-pointer ${user.status === 0 ? 'inactive' : ''}`}>
            <div className="actions">
              <Div if={user.status !== undefined}>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={user.status === 1}
                      onChange={(event, checked) => changeStatus(user.uid, checked)}
                      name="checkedB"
                      color="primary"
                      size="small"
                    />
                  }
                  label={user.status === 1 ? "Ativado" : "Desativado"}
                  labelPlacement="start"
                  className={user.status === 0 ? 'custom-switch' : ''}
                />
              </Div>
              <Div if={user.status === undefined} onClick={() => removeTemporaryUser(user)}><i className="material-icons" title="Remover usuário">delete</i></Div>
            </div>
            <ProfilePicture profilePictureUrl={user.profilePictureUrl} />
            <div className="card-title">{user.name}</div>
          </div>
        )}
      </div>
      <Dialog open={props.openCreateUser}>
        <div className="dialog-container large">
          <div className="dialog-text dialog-text-big">Criar Usuário - {props.activeComponent === SETTINGS_COMPONENTS.TIME_PETROS ? 'Especialista' : 'Administrador'}</div>
          <div className="dialog-text">
            <div className="profile-login">
              <form className="login-form" onSubmit={props.onCreateSubmit}>
                <div className="input-component">
                  <label>Nome:</label>
                  <input id="inp_name" name="name" type="text" required />
                </div>
                <div className="input-component">
                  <label>E-mail:</label>
                  <input id="inp_email" name="email" type="email" required />
                </div>
                <div className="input-component">
                  <label>Senha:</label>
                  <input id="inp_password" name="password" type="password" minLength={6} autoComplete="off" required />
                </div>
                <div className="dialog-buttons">
                  <button type="button" className="primary-button" onClick={() => props.setOpenCreateUser(false)}>Cancelar</button>
                  <button type="submit" className="primary-button">Confirmar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
      <BackdropLoading loading={loading} />
    </React.Fragment>
  )
}