import React from 'react';
import Routes from './Routes';
import UserManager from './managers/UserManager';
import AuthService from './services/AuthService'
import { IAppContext, AppContext } from './services/AppContextService';
import ErrorService from './services/ErrorService';
import DatabaseService, { DatabaseQuery } from './services/DatabaseService';

import './index.css';
import './bootstrap-grid.min.css'
import './variables.css'
import './fonts.css'

interface IAppProps {
  history: any
}

interface IAppState {
  context: IAppContext
  userRef: DatabaseQuery | null
}

export default class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)
    this.state = {
      context: {
        isLogged: AuthService.isLogged(),
        currentUser: AuthService.getCurrentUser(),
        userPermission: '',
        profilePictureUrl: ''
      },
      userRef: null
    }
  }

  componentDidMount() {
    AuthService.getCurrentUserAsync(user => {
      if (user) {
        AuthService.getUserPermission(user.uid, userPermission => this.setState({
          context: {
            currentUser: user,
            isLogged: true,
            userPermission
          } as IAppContext
        }))
        UserManager.getWithListen(user.uid, (userObj, userRef) => {
          this.setState({ userRef })
          if (userObj.profilePicture) {
            UserManager.getUserPicture(userObj.profilePicture.fullPath, url => {
              const context = this.state.context
              context.profilePictureUrl = url
              this.setState({ context })
            })
          }
        })
      } else {
        if (this.state.userRef) {
          DatabaseService.unMountListening(this.state.userRef)
        }
        this.setState({ context: { isLogged: false } as IAppContext })
        AuthService.setLoginState('0')
      }
    })
  }

  componentDidCatch(error: Error) {
    ErrorService.setError(error)
  }

  componentWillUnmount() {
    if (this.state.userRef) {
      DatabaseService.unMountListening(this.state.userRef)
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state.context} >
        <Routes />
      </AppContext.Provider>
    )
  }
}
