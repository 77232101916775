import Template from './Template';
import DatabaseService from '../services/DatabaseService';
import { IAppContext } from '../services/AppContextService';
import { DATABASE_REFS, MEDIAS_KEYS } from '../Constants';

//MODEL
export class MediaAccount extends Template {
  order: number = 0
  username: string = ''
  passwordHash: string = ''
}

export interface IMediaAccount {
  [key: string]: MediaAccount
}

export default class MediaAccountManager {

  private context: IAppContext;
  private uid?: string;

  constructor(context: IAppContext, uid?: string) {
    this.context = context

    if (uid) {
      this.uid = uid
    } else if (this.context.currentUser) {
      this.uid = this.context.currentUser.uid
    }
  }

  //METHODS
  getAll = (callback: (medias: IMediaAccount) => void) => {
    if (this.uid) {
      MediaAccountManager.getAll(this.uid, callback)
    }
  }

  save = (mediaId: string, mediaAccount: MediaAccount, onFinish?: () => void) => {
    if (this.uid) {
      MediaAccountManager.save(this.uid, mediaId, mediaAccount, onFinish)
    }
  }

  static getAll = (uid: string, callback: (medias: IMediaAccount) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.ACCOUNTS, uid)
    DatabaseService.get(ref, medias => {
      for (let media in medias) {
        if (media === MEDIAS_KEYS.INSTAGRAM_FEED) {
          medias[MEDIAS_KEYS.INSTAGRAM_IGTV] = medias[MEDIAS_KEYS.INSTAGRAM_FEED]
          medias[MEDIAS_KEYS.INSTAGRAM_STORIES] = medias[MEDIAS_KEYS.INSTAGRAM_FEED]
        }
      }
      callback(medias)
    })
  }

  static save = (uid: string, mediaId: string, mediaAccount: MediaAccount, onSucess?: () => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.ACCOUNTS, uid, mediaId)
    DatabaseService.save(ref, mediaAccount, onSucess)
  }
}