import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { AppContext } from './services/AppContextService';
import { ROUTES } from './Constants'

import Login from './pages/Login'
import CreateAccount from './pages/CreateAccount'
import CompleteAccount from './pages/CompleteAccount'
// import Dashboard from './pages/Dashboard'
import ErrorPage from './pages/ErrorPage'
import Calendar from './pages/Calendar'
import Settings from './pages/Settings'
import Studio from './pages/Studio'
import MediaAccount from './pages/ListMediaAccount'
import PageNotFound from './pages/PageNotFound'
import Profile from './pages/Profile';

const PrivateRoute = ({ component: Component, context, ...rest }) => (
  <Route {...rest} render={props => (
    context.isLogged
      ? <Component {...props} />
      : <Redirect to={ROUTES.LOGIN} />
  )} />
)

export default function Routes() {
  return (
    <AppContext.Consumer>
      {(context) => (
        <BrowserRouter>
          <Switch>
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.CREATE_ACCOUNT} component={CreateAccount} />
            <Route exact path={ROUTES.ERROR} component={ErrorPage} />
            <PrivateRoute exact path={ROUTES.CALENDAR} component={Calendar} context={context} />
            <PrivateRoute exact path={ROUTES.COMPLETE_ACCOUNT} component={CompleteAccount} context={context} />
            <PrivateRoute exact path={ROUTES.DASHBOARD} component={Calendar} context={context} />
            <PrivateRoute exact path={ROUTES.MEDIA_ACCOUNT} component={MediaAccount} context={context} />
            <PrivateRoute exact path={ROUTES.SETTINGS} component={Settings} context={context} />
            <PrivateRoute exact path={ROUTES.STUDIO} component={Studio} context={context} />
            <PrivateRoute exact path={ROUTES.PROFILE} component={Profile} context={context} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      )}
    </AppContext.Consumer>
  )
}