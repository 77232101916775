import React from 'react'
import DatePicker from "react-datepicker"
import { IStepsProps, IStudioState } from '../../pages/Studio'

export default function Step2(props: IStepsProps) {
  const getMinDate = () => {
    const cDate = new Date()
    return new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), cDate.getHours(), cDate.getMinutes() + 30)
  }

  const setPostDate = (date: Date | null) => {
    props.onChangeState({ postDate: date, datePopover: false } as IStudioState)

    const inpTime = document.getElementById('inp-time') as HTMLInputElement
    if (date && date < getMinDate()) {
      inpTime.setCustomValidity('O horário precisa ser no mínimo 30 minutos adiante a hora atual')
    } else {
      inpTime.setCustomValidity('')
    }
  }

  return (
    <div className="steps step-2">
      <div className="step-title">2. Data e hora do agendamento</div>
      <div className="step-description">Use os menus abaixo para selecionar a data e digitar a hora do seu post.</div>
      <div className="date-time-placeholder">
        <div className="datetime-container">
          <div className="datetime-title">Data</div>
          <div className="datetime-value">
            <input className="datetime-validation" required={props.state.datePopover} />
            <DatePicker
              selected={props.state.postDate}
              onChange={date => setPostDate(date)}
              minDate={new Date()}
              dateFormat="dd/MM/yyyy"
              locale="pt"
              className="date-picker-input" />
          </div>
        </div>
      </div>
      <div className="date-time-placeholder">
        <div className="datetime-container">
          <div className="datetime-title">Hora</div>
          <div className="datetime-value">
            <input id="inp-time" className="datetime-validation" />
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              selected={props.state.postDate}
              onChange={date => setPostDate(date)}
              dateFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Hora"
              className="date-picker-input" />
          </div>
        </div>
      </div>
    </div>
  )
}