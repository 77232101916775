import React, { useState, useEffect } from 'react'
import Div from './Div'
import ProgressCircle from './ProgressCircle'
import { FILE_TYPES } from '../../Constants'
import './PreviewFile.scss'

import postPreview from '../../assets/images/post_preview.svg'

interface IPreviewFileProps {
  url?: string | null
  contentType?: string | null
}

export default function PreviewFile(props: IPreviewFileProps) {
  const [loadingFile, setLoadingFile] = useState(false)
  const [loadedImage, setLoadedImage] = useState(postPreview)
  const [imageClassName, setImageClassName] = useState('preview-image')
  const [videoClassName, setVideoClassName] = useState('hidden')
  const [previewClassName, setPreviewClassName] = useState('preview-content no-image')

  const loadFile = () => {
    const videoPreview = document.getElementById('video-source') as HTMLSourceElement
    const image = new Image()

    image.onload = () => {
      setImageClassName('preview-image')
      setPreviewClassName('preview-content')
      setLoadedImage(image.src)
      setLoadingFile(false)
    }

    const clearLoadedFile = () => {
      setLoadedImage(postPreview)
      setImageClassName('preview-image')
      setPreviewClassName('preview-content no-image')
      setVideoClassName('hidden')
    }

    clearLoadedFile()

    if (props.url && props.contentType) {
      setLoadingFile(true)

      if (props.contentType === FILE_TYPES.JPG || props.contentType === FILE_TYPES.PNG) {
        image.src = props.url
      } else if (props.contentType === FILE_TYPES.MP4) {
        setImageClassName('preview-image hidden')
        setPreviewClassName('preview-content')
        setVideoClassName('')
        const videoElement = videoPreview.parentElement! as HTMLVideoElement
        videoPreview.src = props.url
        videoElement.load()
        setLoadingFile(false)
      }
    } else {
      clearLoadedFile()
    }
  }

  useEffect(loadFile, [props.url])

  return (
    <div id="div-preview" className={previewClassName}>
      <Div if={loadingFile} className="file-loading"><ProgressCircle /></Div>
      <img className={imageClassName} src={loadedImage} alt="foto do usuário" />
      <video controls className={videoClassName}><source id="video-source" type="video/mp4" /></video>
    </div>
  )
}