import React from 'react'

import instagramFeed from '../../assets/images/Insta_Icon.svg'
import instagramStories from '../../assets/images/Stories_Icon.svg'
import instagramIGTV from '../../assets/images/IGTV_Icon.svg'
import facebook from '../../assets/images/Facebook_Icon_Color.svg'
import instagramFeedInactive from '../../assets/images/Insta_Icon_inactive.svg'
import instagramStoriesInactive from '../../assets/images/Stories_Icon_inactive.svg'
import instagramIGTVInactive from '../../assets/images/IGTV_Icon_inactive.svg'
import facebookInactive from '../../assets/images/Facebook_Icon_inactive.svg'
import Constants, { MEDIAS_LABELS } from '../../Constants'

interface IMediaImageProps {
  mediaKey?: string
  active?: boolean
  className?: string
}

export default function MediaImage(props: IMediaImageProps) {
  const getMediaImage = () => {
    if (props.mediaKey) {
      const active = props.active === undefined || props.active
      switch (Constants.getValue(MEDIAS_LABELS, props.mediaKey)) {
        case MEDIAS_LABELS.FACEBOOK_FEED:
          return active ? facebook : facebookInactive
        case MEDIAS_LABELS.INSTAGRAM_STORIES:
          return active ? instagramStories : instagramStoriesInactive
        case MEDIAS_LABELS.INSTAGRAM_IGTV:
          return active ? instagramIGTV : instagramIGTVInactive
        default:
          return active ? instagramFeed : instagramFeedInactive
      }
    }
  }

  return <img src={getMediaImage()} alt="icone da rede" className={props.className} />
}