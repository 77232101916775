import React from 'react'
import { format } from 'date-fns'
import swal from 'sweetalert'
import EmojiArea from './EmojiArea'
import PreviewFile from '../utils/PreviewFile'
import { IStepsProps, IStudioState } from '../../pages/Studio'
import PostManager, { Post } from '../../managers/PostManager'
import { UploadFile } from '../../services/StorageService'
import { POST_STATUS } from '../../Constants'

interface IStep4Props extends IStepsProps {
  setPostText: (text: string) => void
  postId?: string
  postManager?: PostManager
}

export default function Step4(props: IStep4Props) {
  const uploadFile = (postId: string, currentFile: File, qtMedias: number, mediaIndex: number, callback: (fullPath: UploadFile) => void) => {
    props.postManager!.uploadFile(postId,
      currentFile,
      progress => {
        const multiplier = 100 / qtMedias
        const parcialProgress = progress / qtMedias
        props.onChangeState({ postingProgress: Math.floor((multiplier * mediaIndex) + parcialProgress) } as IStudioState)
      },
      file => callback(file))
  }

  const saveNewPost = (post: Post, qtSelectedMedias: number, mediaIndex: number) => {
    props.postManager!.push(post, (postId: string) => {
      if (props.state.selectedFiles?.length) {
        uploadFile(postId, props.state.selectedFiles[0], qtSelectedMedias, mediaIndex, file => {
          post.postFiles.push(file)
          props.postManager!.save(postId, post, () => {
            mediaIndex++
            if (mediaIndex < qtSelectedMedias) {
              finishPost(mediaIndex)
            } else {
              props.onChangeState({ postingProgress: 100 } as IStudioState)
            }
          })
        })
      } else {
        mediaIndex++
        if (mediaIndex < qtSelectedMedias) {
          finishPost(mediaIndex)
        } else {
          props.onChangeState({ postingProgress: 100 } as IStudioState)
        }
      }
    })
  }

  const savePost = (post: Post) => {
    props.postManager!.save(props.postId!, post, () => {
      props.onChangeState({ postingProgress: 100 } as IStudioState)
    })
  }

  const finishPost = (mediaIndex: number = 0) => {
    props.onChangeState({ finishModalShow: true } as IStudioState)
    const qtSelectedMedias = props.state.selectedMedias.length

    const post = new Post()
    post.schedulingDate = format(props.state.postDate!, 'dd/MM/yyyy')
    post.schedulingHour = format(props.state.postDate!, 'HH:mm')
    post.account = props.state.selectedMedias[mediaIndex]
    post.username = props.state.medias[post.account].username
    post.text = props.state.postText

    if (!props.state.isClient) {
      post.postStatus = POST_STATUS.REVIEW
    }

    // EDITING POST
    if (props.state.selectedPost) {
      // CHECKING USER CHANGED THE FILE
      if (props.state.selectedFiles?.length) {
        // CHECKING THERE IS SERVER FILE
        if (props.state.selectedPost.postFiles?.length) {
          // REMOVING SERVER FILE AND UPLOADING NEW
          PostManager.removeFile(props.state.selectedPost.postFiles[0].fullPath, () => {
            uploadFile(props.postId!, props.state.selectedFiles[0], qtSelectedMedias, mediaIndex, file => {
              post.postFiles.push(file)
              savePost(post)
            })
          })
        } else {
          // UPLOADING FILE
          uploadFile(props.postId!, props.state.selectedFiles[0], qtSelectedMedias, mediaIndex, file => {
            post.postFiles.push(file)
            savePost(post)
          })
        }
        // CHECKING THERE IS SERVER FILE AND USER WANTS DELETE
      } else if (props.state.selectedPost.postFiles?.length && !props.state.fileLoadedURL) {
        // REMOVING SERVER FILE
        PostManager.removeFile(props.state.selectedPost.postFiles[0].fullPath, () => savePost(post))
      } else {
        // KEEPING THE SERVER FILE
        post.postFiles = props.state.selectedPost.postFiles
        savePost(post)
      }
    } else {
      // SAVING A NEW POST
      saveNewPost(post, qtSelectedMedias, mediaIndex)
    }
  }

  const openModalDialog = () => {
    const textarea = document.getElementById('txt-post-text-validation') as HTMLTextAreaElement

    //VALIDATIONS
    if (!props.state.postDate) {
      props.onChangeState({ datePopover: true } as IStudioState)
    } else if (!props.state.selectedMedias.length) {
      props.onChangeState({ mediaRequired: true } as IStudioState)
    } else if (props.state.remainCharacters < 0) {
      textarea.setCustomValidity('Máximo 2000 Caracteres')
    } else {
      textarea.setCustomValidity('')
      swal({
        title: props.state.isClient ? 'Tem certeza que deseja solicitar esta publicação?' : 'Tem certeza que deseja enviar esta publicação para revisão do cliente?',
        icon: "warning",
        buttons: ["NÃO", "SIM"]
      }).then(value => {
        if (value)
          finishPost()
      })
    }
  }

  return (
    <div className="steps step-4">
      <div className="step-title">4. Preview do post</div>
      <div className="preview-placeholder">
        <div className="preview-container">
          <PreviewFile url={props.state.fileLoadedURL} contentType={props.state.fileLoadedType} />
          <div className="text-container">
            <textarea id="txt-post-text-validation" className="text-area no-opacity"></textarea>
            <EmojiArea loadFunction={func => props.onChangeState({ loadEmojiArea: func } as IStudioState)} onChange={text => props.setPostText(text)} />
          </div>
          <div className="text-info">
            <span>{props.state.hashtagCount} hashtags</span>
            <span className={props.state.remainCharacters < 0 ? 'critical-alert' : ''}>{props.state.remainCharacters} caracteres</span>
          </div>
        </div>
        <div className="button-container">
          <button className="primary-button finish-button" onClick={() => openModalDialog()}>{props.state.isClient ? 'Solicitar' : 'Enviar para o cliente'}</button>
        </div>
      </div>
    </div>
  )
}