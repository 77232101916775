import React from 'react'
import { useHistory } from 'react-router'
import { ROUTES, LOCAL_STORAGE_KEYS } from '../Constants'
import './ErrorPage.scss'

import logo from '../assets/images/Logo_Petra.svg'
import instagram from '../assets/images/Instagram_Icon_White.svg'
import facebook from '../assets/images/Facebook_Icon_White.svg'
import twitter from '../assets/images/Twitter_Icon.svg'

export default function ErrorPage() {
  const history = useHistory()
  const backDashboard = () => history.push(ROUTES.DASHBOARD)
  const getErrorMessage = () => {
    return sessionStorage.getItem(LOCAL_STORAGE_KEYS.ERROR_MESSAGE)
      ? sessionStorage.getItem(LOCAL_STORAGE_KEYS.ERROR_MESSAGE)!
      : ''
  }

  return (
    <div className="container-fluid p-0 error-page">
      <header className="row error-header">
        <div className="offset-1 col-3">
          <img src={logo} alt="logotipo petros" className="logo" onClick={backDashboard} />
        </div>
        <div className="offset-2 col-4">
          <div className="header-title">Plataforma Petros</div>
          <div className="header-info">Gerenciamento de redes sociais</div>
        </div>
      </header>

      <main className="row error-main">
        <div className="main-title">Oops!</div>
        <div className="main-text">ALGO DE ERRADO ACONTECEU...</div>
        <div className="main-text back-action" onClick={backDashboard}>CLIQUE PARA RETORNAR PARA O <span className="highlight">INÍCIO</span></div>
        <div className="error-details">Detalhes do erro:</div>
        <div className="error-block">{getErrorMessage()}</div>
      </main>

      <footer className="row error-footer">
        <div className="offset-1 col-1 social-networks">
          <a href="https://www.instagram.com/agpetrosdigital/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram logo" /></a>
          <a href="https://www.facebook.com/Petra-Digital-1979699188742637" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook logo" /></a>
          <img src={twitter} alt="twitter logo" />
        </div>
        <div className="col-2 copyright">Todos os direitos reservados - Petros Digital 2020</div>
      </footer>
    </div>
  )
}