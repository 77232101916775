import Template from './Template';
import DatabaseService, { DatabaseQuery } from '../services/DatabaseService';
import StorageService, { UploadFile } from '../services/StorageService';
import ErrorService from '../services/ErrorService';
import { IAppContext } from '../services/AppContextService';
import { DATABASE_REFS, POST_STATUS } from '../Constants';

//MODEL
export class Post extends Template {
  id = ''
  schedulingDate: string = ''
  schedulingHour: string = ''
  account: string = ''
  username: string = ''
  text: string = ''
  postStatus = POST_STATUS.REQUESTED
  postFiles: UploadFile[] = []
}

interface IPost {
  [key: string]: Post
}

export interface IPendingClient {
  [uid: string]: Post[]
}

const postsRef = DatabaseService.createRef(DATABASE_REFS.POSTS)

export default class PostManager {

  private context: IAppContext;
  private uid?: string;

  constructor(context: IAppContext, uid?: string) {
    this.context = context

    if (uid) {
      this.uid = uid
    } else if (this.context.currentUser) {
      this.uid = this.context.currentUser.uid
    }
  }

  //PUBLIC METHODS

  get = (id: string, callback: (post: Post) => void) => {
    if (this.uid) {
      PostManager.get(id, this.uid, callback)
    }
  }

  save = (id: string, post: Post, onFinish?: (postId: string) => void) => {
    if (this.uid) {
      PostManager.save(id, post, this.uid, onFinish)
    }
  }

  getAllByDayWithListen = (day: string, callback: (posts?: Post[], ref?: DatabaseQuery) => void) => {
    if (this.uid) {
      PostManager.getAllByDayWithListen(this.uid, day, callback)
    }
  }

  push = (post: Post, onFinish: (postId: string) => void) => {
    if (this.uid) {
      let ref = postsRef.child(this.uid)
      const postKey = DatabaseService.getPushKey(ref)

      if (postKey) {
        this.save(postKey, post, onFinish)
      }
    }
  }

  remove = (id: string, onFinish: () => void) => {
    if (this.uid) {
      PostManager.remove(id, this.uid, onFinish)
    }
  }

  uploadFile = (postId: string, file: File, onProgress: (progress: number) => void, onFinish: (fullPath: UploadFile) => void) => {
    if (this.uid) {
      const ref = StorageService.createRef(DATABASE_REFS.POSTS, this.uid, postId, file.name)
      const task = StorageService.uploadFile(ref, file)
      StorageService.createUploadListen(task, onProgress, ErrorService.setError, onFinish)
    }
  }

  getPending = (callback: (posts: Post[], ref: DatabaseQuery) => void) => {
    if (this.uid) {
      const ref = postsRef.child(this.uid).orderByChild('postStatus').equalTo(POST_STATUS.REVIEW)

      DatabaseService.getWithListen(ref,
        (values: IPost) => {
          const posts = []
          for (let key in values) {
            const post = values[key]
            post.id = key
            posts.push(post)
          }
          callback(posts, ref)
        }
      )
    }
  }

  //STATIC METHODS

  static get = (id: string, uid: string, callback: (post: Post) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.POSTS, uid, id)
    DatabaseService.get(ref, callback)
  }

  static save = (id: string, post: Post, uid: string, onSucess?: (postId: string) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.POSTS, uid, id)
    DatabaseService.save(ref, post, () => {
      if (onSucess) {
        onSucess(id)
      }
    })
  }

  static getAllByDayWithListen = (uid: string, day: string, callback: (posts: Post[], ref: DatabaseQuery) => void) => {
    const ref = postsRef.child(uid).orderByChild('schedulingDate').equalTo(day)
    DatabaseService.getWithListen(ref,
      (values: IPost) => {
        const posts = []
        for (let key in values) {
          const post = values[key]
          post.id = key
          posts.push(post)
        }
        callback(posts, ref)
      }
    )
  }

  static remove = (id: string, uid: string, onFinish: () => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.POSTS, uid, id)
    const imagesRef = StorageService.createRef('posts', uid, id)
    DatabaseService.remove(ref, () => StorageService.removeAll(imagesRef, onFinish))
  }

  static getFile = (imageRef: string, callback: (url: string) => void) => {
    const ref = StorageService.createRef(imageRef)
    StorageService.getDownloadUrl(ref, callback)
  }

  static removeFile = (imageRef: string, callback: () => void) => {
    const ref = StorageService.createRef(imageRef)
    StorageService.removeFile(ref, callback)
  }

  static getPendingAllClients = (callback: (clientsPending: IPendingClient, ref: DatabaseQuery) => void) => {
    const ref = postsRef.orderByChild('postStatus')

    DatabaseService.getWithListen(ref, (clients: any) => {
      const clientsPending: IPendingClient = {}
      for (let client in clients) {
        const posts = clients[client]
        const filteredPosts = []
        for (let postKey in posts) {
          const post = posts[postKey] as Post
          if (post.postStatus === POST_STATUS.REQUESTED) {
            filteredPosts.push(post)
          }
        }
        clientsPending[client] = filteredPosts
      }
      callback(clientsPending, ref)
    })
  }

}