import React, { useState, useEffect } from 'react'
import Div from './Div'
import './PageControl.scss'

interface IPageControlProps {
  pageLimit: number
  listLength: number
  currentPage: number
  onPageChange: (page: number) => void
}

export default function PageControl(props: IPageControlProps) {
  const [pages, setPages] = useState([0])

  const buildPages = () => {
    const pagesNumber = Math.ceil(props.listLength / props.pageLimit)
    const list = []
    if (pagesNumber > 1) {
      for (let page = 0; page < pagesNumber; page++) {
        list.push(page)
      }
    }
    setPages(list)
  }

  useEffect(buildPages, [props.listLength])

  return (
    <div className="page-control">
      {pages.map(page => <Div key={page} className="page" active={page === props.currentPage} onClick={() => props.onPageChange(page)}></Div>)}
    </div>
  )
}