import Template from './Template';
import DatabaseService, { DatabaseQuery } from '../services/DatabaseService';
import { IAppContext } from '../services/AppContextService';
import AuthService from '../services/AuthService';
import StorageService, { UploadFile } from '../services/StorageService';
import ErrorService from '../services/ErrorService';
import { DATABASE_REFS, USER_PERMISSIONS, LOGIN_METHODS } from '../Constants';

//MODEL
export class User extends Template {
  email = ''
  name = ''
  methods = [LOGIN_METHODS.EMAIL]
  permission = USER_PERMISSIONS.CLIENT
  profilePicture = {} as UploadFile
}

export class TemporaryUser extends Template {
  id = ''
  name = ''
  email = ''
  password = ''
  permission = USER_PERMISSIONS.SPECIALIST
}

export type UserList = {
  [uid: string]: User
}

type TemporaryUserList = {
  [key: string]: TemporaryUser
}

export default class UserManager {

  private context: IAppContext;

  constructor(context: IAppContext) {
    this.context = context
  }

  //PUBLIC METHODS
  get = (callback: (user: User) => void) => {
    if (this.context.currentUser) {
      UserManager.get(this.context.currentUser.uid, callback)
    }
  }

  save = (user: User, onSuccess?: () => void) => {
    if (this.context.currentUser) {
      UserManager.save(user, this.context.currentUser.uid, onSuccess)
    }
  }

  uploadUserPicture = (file: File, onProgress: (progress: number) => void, onFinish: (file: UploadFile) => void) => {
    if (this.context.currentUser) {
      const ref = StorageService.createRef(DATABASE_REFS.PROFILE_PICTURES, this.context.currentUser.uid, file.name)
      const task = StorageService.uploadFile(ref, file)
      StorageService.createUploadListen(task, onProgress, ErrorService.setError, onFinish)
    }
  }

  getUserPicture = (callback: (url: string) => void) => {
    if (this.context.currentUser) {
      this.get(user => {
        if (user.profilePicture) {
          UserManager.getUserPicture(user.profilePicture.fullPath, callback)
        }
      })
    }
  }

  changeEmail = (email: string, onSuccess: () => void, onError: (error: any) => void) => {
    this.get(user => {
      AuthService.updateEmail(email, () => {
        user.email = email
        this.save(user, onSuccess)
      }, onError)
    })
  }

  static save = (user: User, uid: string, onSuccess?: () => void, onError?: (error: any) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS, uid)
    DatabaseService.save(ref, user, onSuccess, onError)
  }

  static get = (uid: string, callback: (user: User) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS, uid)
    DatabaseService.get(ref, callback)
  }

  static getByEmail = (email: string, callback: (user: User) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS)
    const query = ref.orderByChild('email').equalTo(email)
    DatabaseService.get(query, callback)
  }

  static getAllSpecialist = (callback: (users: UserList) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS)
    const query = ref.orderByChild('permission').equalTo(USER_PERMISSIONS.SPECIALIST)
    DatabaseService.get(query, callback)
  }

  static getAllAdmin = (callback: (users: UserList) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS)
    const query = ref.orderByChild('permission').equalTo(USER_PERMISSIONS.ADMIN)
    DatabaseService.get(query, callback)
  }

  static getWithListen = (uid: string, callback: (user: User, ref: DatabaseQuery) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.USERS, uid)
    DatabaseService.getWithListen(ref, user => callback(user, ref))
  }

  static getUserPicture = (imageRef: string, callback: (url: string) => void) => {
    const ref = StorageService.createRef(imageRef)
    StorageService.getDownloadUrl(ref, callback)
  }

  static removeUserPicture = (imageRef: string, callback: () => void) => {
    const ref = StorageService.createRef(imageRef)
    StorageService.removeFile(ref, callback)
  }

  static createTemporaryUser = (user: TemporaryUser, onFinish: (error: any) => void) => {
    UserManager.getByEmail(user.email, hasUser => {
      if (hasUser) {
        onFinish({ code: 'auth/email-already-in-use' })
      } else {
        const ref = DatabaseService.createRef(DATABASE_REFS.TEMPORARY_USERS)
        DatabaseService.push(ref, user, onFinish)
      }
    })
  }

  static getTemporaryUser = (email: string, onSuccess: (user: TemporaryUser) => void, onError: (error: any) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.TEMPORARY_USERS).orderByChild('email').equalTo(email)
    DatabaseService.get(ref, data => {
      for (let key in data) {
        const user = data[key] as TemporaryUser
        user.id = key
        onSuccess(user)
        return
      }
      onError({ code: 'auth/user-not-found' })
    }, onError)
  }

  static removeTemporaryUser = (id: string, onFinish?: (error: Error | null) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.TEMPORARY_USERS, id)
    DatabaseService.remove(ref, onFinish)
  }

  static getAllTemporarySpecialist = (callback: (users: TemporaryUserList) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.TEMPORARY_USERS)
    const query = ref.orderByChild('permission').equalTo(USER_PERMISSIONS.SPECIALIST)
    DatabaseService.get(query, callback)
  }

  static getAllTemporaryAdmin = (callback: (users: TemporaryUserList) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.TEMPORARY_USERS)
    const query = ref.orderByChild('permission').equalTo(USER_PERMISSIONS.ADMIN)
    DatabaseService.get(query, callback)
  }

  static setStatus = (uid: string, status: number, onSuccess?: () => void, onError?: (error: any) => void) => {
    UserManager.get(uid, user => {
      user._status = status
      UserManager.save(user, uid, onSuccess, onError)
    })
  }
}