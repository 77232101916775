import React from 'react'
import MediaImage from '../utils/MediaImage';
import PostManager, { Post } from '../../managers/PostManager';
import { AppContext } from '../../services/AppContextService';
import DatabaseService, { DatabaseQuery } from '../../services/DatabaseService';
import Constants, { POST_STATUS_LABELS } from '../../Constants'
import './CalendarPosts.scss'

interface ICalendarPostsProps {
  date: string
  selectedClient: string
  onPostClick: (postId: string) => void
}

interface ICalendarPostsState {
  dayRef: DatabaseQuery | null
  posts: Post[]
  filteredPosts: Post[]
}

export default class CalendarPosts extends React.Component<ICalendarPostsProps, ICalendarPostsState> {
  static contextType = AppContext
  dataLoaded = false

  constructor(props: ICalendarPostsProps) {
    super(props)
    this.state = { posts: [], filteredPosts: [], dayRef: null }
  }

  componentDidMount() {
    if (this.context.currentUser) {
      this.loadPosts()
    }
  }

  componentDidUpdate(prevProps: ICalendarPostsProps) {
    if (this.context.currentUser
      && (!this.dataLoaded
        || prevProps.date !== this.props.date
        || prevProps.selectedClient !== this.props.selectedClient)) {
      this.loadPosts()
    }
  }

  componentWillUnmount() {
    if (this.state.dayRef) {
      DatabaseService.unMountListening(this.state.dayRef)
    }
  }

  getStatusIconClass = (post: Post) => 'post-status-icon ' + post.postStatus.toLowerCase()

  loadPosts = () => {
    if (this.state.dayRef) {
      DatabaseService.unMountListening(this.state.dayRef)
    }
    if (this.props.selectedClient) {
      this.dataLoaded = true
      PostManager.getAllByDayWithListen(this.props.selectedClient, this.props.date, (posts, dayRef) => {
        this.setState({ posts, dayRef }, () => {
          this.filterPosts()
          this.refreshClassesNames()
        })
      })
    }
  }

  filterPosts = (showAll?: boolean) => {
    if (this.state.posts.length > 5 && !showAll) {
      return this.setState({ filteredPosts: this.state.posts.slice(0, 5) })
    }
    return this.setState({ filteredPosts: this.state.posts })
  }

  refreshClassesNames = () => {
    const dayElement = document.getElementById(this.props.date)!
    if (this.state.posts.length) {
      dayElement.classList.add('calendar-day-posts')
    } else {
      dayElement.classList.remove('calendar-day-posts')
    }
  }

  getMoreText = () => {
    if (this.state.posts.length <= 5) {
      return ''
    }
    const quant = this.state.posts.length - this.state.filteredPosts.length
    return quant ? quant + ' mais...' : 'menos'
  }

  toggleMore = () => {
    const dayDiv = document.getElementById(this.props.date)!
    if (this.state.posts.length === this.state.filteredPosts.length) {
      dayDiv.classList.remove('active')
      return this.filterPosts()
    }
    dayDiv.classList.add('active')
    this.filterPosts(true)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.filteredPosts.map((post, key) => {
          return (
            <React.Fragment key={key}>
              <MediaImage mediaKey={post.account} className="post-icon" />
              <div className="post-name" onClick={() => this.props.onPostClick(post.id)}>{post.username}</div>
              <div className="post-time" onClick={() => this.props.onPostClick(post.id)}>{post.schedulingHour}</div>
              <div className={this.getStatusIconClass(post)} title={Constants.getValue(POST_STATUS_LABELS, post.postStatus)} />
            </React.Fragment>
          )
        })}
        <div className="show-more" onClick={this.toggleMore}>{this.getMoreText()}</div>
      </React.Fragment>
    )
  }
}