import FirebaseService from './FirebaseService'
import ClientManager from '../managers/ClientManager'
import UserManager, { User } from '../managers/UserManager'
import ErrorService from './ErrorService'
import { ROUTES, USER_PERMISSIONS, LOCAL_STORAGE_KEYS } from '../Constants'

export type FirebaseUser = FirebaseService.User
type FirebaseUserCredencial = FirebaseService.auth.UserCredential
type FirebaseProvider = FirebaseService.auth.AuthProvider

const FirebaseAuth = FirebaseService.auth()

const getCurrentUser = () => FirebaseAuth.currentUser
const setLoginState = (logged: string) => sessionStorage.setItem(LOCAL_STORAGE_KEYS.LOGGED, logged)
const isLogged = () => !!sessionStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED)
const createAccount = (email: string, password: string) => FirebaseAuth.createUserWithEmailAndPassword(email, password)

const getCurrentUserAsync = (callback: (currentUser: FirebaseUser | null) => void) => {
  FirebaseAuth.onAuthStateChanged(currentUser => callback(currentUser))
}

const getUserPermission = (uid: string, callback: (permission: string) => void) => {
  UserManager.get(uid, user => callback(user.permission))
}

const logout = (callback: (value: any) => void) => {
  sessionStorage.clear()
  FirebaseAuth.signOut().then(callback)
}

const resetPassword = (email: string, callback: (error: any) => void) => {
  FirebaseAuth.sendPasswordResetEmail(email)
    .then(callback)
    .catch(error => {
      callback(error)
      console.error(error)
    })
}

const successfulLogin = (credencial: FirebaseUserCredencial, callback: (route: string) => void) => {
  if (credencial.user) {
    const uid = credencial.user.uid
    UserManager.get(uid, user => {

      if (user.permission === USER_PERMISSIONS.CLIENT) {
        ClientManager.get(uid, client => client!.category ? callback(ROUTES.DASHBOARD) : callback(ROUTES.COMPLETE_ACCOUNT))
      } else {
        callback(ROUTES.DASHBOARD)
      }
      setLoginState('1')
    })
  }
}

const failLogin = (error: any) => {
  setLoginState('0')
  console.error(error)
}

const loginWithEmail = (email: string, password: string, successfulCallback: (route: string) => void, failCallback: (error: any) => void) => {
  FirebaseAuth.signInWithEmailAndPassword(email, password)
    .then(credential => {
      if (credential.user) {
        const uid = credential.user.uid
        UserManager.get(uid, user => {
          if (user._status) {
            successfulLogin(credential, successfulCallback)
          } else {
            logout(() => {
              const error = { code: 'user-blocked' }
              failCallback(error)
              failLogin(error)
            })
          }
        })
      }
    })
    .catch(error => {
      if (error.code === 'auth/user-not-found') {
        loginWithTemporaryUser(email, password, successfulCallback, failCallback)
      } else {
        failCallback(error)
        failLogin(error)
      }
    })
}

const loginWithTemporaryUser = (email: string, password: string, successfulCallback: (route: string) => void, failCallback: (error: any) => void) => {
  UserManager.getTemporaryUser(email, temporaryUser => {
    if (temporaryUser.password === password) {
      createAccount(email, password)
        .then(credential => {
          const user = new User()
          user.email = temporaryUser.email
          user.name = temporaryUser.name
          user.permission = temporaryUser.permission

          UserManager.save(user, credential.user!.uid, () => {
            UserManager.removeTemporaryUser(temporaryUser.id, () => successfulLogin(credential, successfulCallback))
          }, failCallback)
        })
        .catch(failCallback)
    } else {
      failCallback({ code: 'auth/wrong-password' })
    }
  }, failCallback)
}

const loginWithFacebook = (successfulCallback: (route: string) => void, failCallback: (error: Error) => void) => {
  loginWithProvider(new FirebaseService.auth.FacebookAuthProvider(), successfulCallback, failCallback)
}

const loginWithGoogle = (successfulCallback: (route: string) => void, failCallback: (error: Error) => void) => {
  loginWithProvider(new FirebaseService.auth.GoogleAuthProvider(), successfulCallback, failCallback)
}

const loginWithProvider = (provider: FirebaseProvider, successfulCallback: (route: string) => void, failCallback: (error: Error) => void) => {
  FirebaseAuth.signInWithPopup(provider)
    .then(credential => successfulLogin(credential, successfulCallback))
    .catch(error => {
      failCallback(error)
      failLogin(error)
    })
}

const linkFacebook = () => {
  const provider = new FirebaseService.auth.FacebookAuthProvider()
  const currentUser = getCurrentUser()
  if (currentUser) {
    currentUser.linkWithPopup(provider)
      .then(console.log)
      .catch(console.error)
  }
}

const updateEmail = (email: string, successfulCallback: () => void, failCallback?: (error: any) => void) => {
  const currentUser = getCurrentUser()
  if (currentUser)
    currentUser.updateEmail(email)
      .then(successfulCallback)
      .catch(failCallback ? failCallback : ErrorService.setError)
}

const updatePassword = (password: string, successfulCallback: () => void, failCallback?: (error: any) => void) => {
  const currentUser = getCurrentUser()
  if (currentUser)
    currentUser.updatePassword(password)
      .then(successfulCallback)
      .catch(failCallback ? failCallback : ErrorService.setError)
}


const reauthenticateWithEmail = (email: string, password: string, successfulCallback: () => void, failCallback?: (error: any) => void) => {
  const currentUser = getCurrentUser()
  const credential = FirebaseService.auth.EmailAuthProvider.credential(email, password)
  if (currentUser) {
    currentUser.reauthenticateWithCredential(credential)
      .then(successfulCallback)
      .catch(failCallback ? failCallback : ErrorService.setError)
  }
}

export default {
  loginWithEmail,
  loginWithFacebook,
  loginWithGoogle,
  linkFacebook,
  isLogged,
  getCurrentUser,
  getCurrentUserAsync,
  createAccount,
  resetPassword,
  getUserPermission,
  logout,
  setLoginState,
  updateEmail,
  updatePassword,
  reauthenticateWithEmail
}