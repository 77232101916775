import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import Constants, { EXCEPTION_ERRORS } from '../../Constants'

interface IContainerProps {
    errorCode?: string
    onInit?: Function
    onCloseError?: Function
}

export default class Container extends React.Component<IContainerProps> {
    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit()
        }
    }

    closeModal() {
        if (this.props.onCloseError) {
            this.props.onCloseError()
        }
    }

    getErrorMessage() {
        if (this.props.errorCode) {
            console.error(this.props.errorCode)
            return Constants.getValue(EXCEPTION_ERRORS, this.props.errorCode) ? Constants.getValue(EXCEPTION_ERRORS, this.props.errorCode) : EXCEPTION_ERRORS.UNKNOW
        }
        return ''
    }

    render() {
        return (
            <div className="container-fluid p-0">
                {this.props.children}
                <Dialog open={!!this.props.errorCode} onClose={() => this.closeModal()}>
                    <div className="dialog-container">
                        <div className="dialog-icon dialog-error">
                            <span className="material-icons">error</span>
                        </div>
                        <div className="dialog-text">{this.getErrorMessage()}</div>
                        <div className="dialog-buttons">
                            <button className="primary-button" onClick={() => this.closeModal()}>OK</button>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}