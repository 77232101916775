import React, { useEffect, useState } from 'react'
import Div from '../utils/Div'
import { IStepsProps, IStudioState } from '../../pages/Studio'
import { FILE_TYPES } from '../../Constants'

type FileCard = {
  fileName: string
  fileIcon: string
  onDownload?: () => void
}

export default function Step1(props: IStepsProps) {
  const [fileCard, setFileCard] = useState({} as FileCard)

  const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const input = document.getElementById('inp-upload-file') as HTMLInputElement
    input.setCustomValidity('')

    if (files && files.length) {
      const callFileException = (errorMessage: string) => {
        props.onChangeState({ fileLoadedURL: '', fileLoadedType: '' } as IStudioState)
        input.setCustomValidity(errorMessage)
        document.forms[0].reportValidity()
      }

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)!
        if (props.state.fileLoadedURL) {
          URL.revokeObjectURL(props.state.fileLoadedURL)
        }
        if (file.size > 100 * (10 ** 6)) {
          callFileException('O arquivo não pode ser maior que 100MB')
          return
        }

        const url = URL.createObjectURL(file)
        if (file.type === FILE_TYPES.JPG || file.type === FILE_TYPES.PNG || file.type === FILE_TYPES.MP4) {
          const selectedFiles = props.state.selectedFiles
          selectedFiles.push(file)
          props.onChangeState({ fileLoadedURL: url, fileLoadedType: file.type, selectedFiles } as IStudioState)
        } else {
          URL.revokeObjectURL(url)
          callFileException(file.type + ' não é um formato aceito')
          return
        }
      }
    }
  }

  const getFileIcon = (fileType: string) => {
    if (fileType === FILE_TYPES.JPG || fileType === FILE_TYPES.PNG) {
      return 'image'
    }
    if (fileType === FILE_TYPES.MP4) {
      return 'movie'
    }
    return ''
  }

  const deleteFile = () => {
    URL.revokeObjectURL(props.state.fileLoadedURL)
    setFileCard({} as FileCard)
    props.onChangeState({ selectedFiles: [] as File[], fileLoadedURL: '', fileLoadedType: '', fileLoadedName: '' } as IStudioState)
  }

  const updateFileCard = () => {
    if (props.state.selectedFiles.length > 0) {
      setFileCard({
        fileName: props.state.selectedFiles[0].name,
        fileIcon: getFileIcon(props.state.selectedFiles[0].type)
      })
    } else if (props.state.fileLoadedURL) {
      setFileCard({
        fileName: props.state.fileLoadedName,
        fileIcon: getFileIcon(props.state.fileLoadedType),
        onDownload: () => {
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = () => {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(xhr.response)
            link.download = props.state.fileLoadedName
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)
          }
          xhr.open('GET', props.state.fileLoadedURL)
          xhr.send()
        }
      })
    }
  }

  useEffect(updateFileCard, [props.state.fileLoadedURL])

  return (
    <div className="steps step-1">
      <div className="step-title">1. Enviar imagem</div>
      <div className="upload-area">
        <Div if={!fileCard.fileIcon}>
          <input id="inp-upload-file" type="file" className="upload-input" accept=".jpg,.png,.mp4" onChange={selectFiles} />
          <div className="upload-icon"><span className="material-icons">add_photo_alternate</span></div>
          <div className="upload-text">Clique aqui para enviar uma imagem (.jpg ou .png) ou vídeo (.mp4). Você também poderá arrastar um arquivo do seu computador até esta janela.</div>
        </Div>
        <Div if={!!fileCard.fileIcon} className="file-list">
          <div className="file-card">
            <div className="file-actions">
              <Div if={!!fileCard.onDownload} onClick={fileCard.onDownload}><i className="material-icons" title="Baixar arquivo">get_app</i></Div>
              <Div onClick={deleteFile}><i className="material-icons" title="Remover arquivo">delete</i></Div>
            </div>
            <div className="file-icon"><i className="material-icons">{fileCard.fileIcon}</i></div>
            <div className="file-name">{fileCard.fileName}</div>
          </div>
        </Div>
      </div>
    </div>
  )
}