import React from 'react'
import { format } from 'date-fns'
import Header from '../components/utils/Header'
import SidebarMenu from '../components/utils/SidebarMenu'
import CalendarPosts from '../components/calendar/CalendarPosts';
import Div from '../components/utils/Div'
import ClientSelect from '../components/utils/ClientSelect';
import BackdropLoading from '../components/utils/BackdropLoading';
import PostPreview from '../components/calendar/PostPreview';
import PostManager, { Post, IPendingClient } from '../managers/PostManager';
import ClientManager from '../managers/ClientManager';
import AppContextService, { AppContext } from '../services/AppContextService';
import DatabaseService, { DatabaseQuery } from '../services/DatabaseService';
import Constants, { MENU_ITEMS, MONTHS, DAYS_NAMES, USER_PERMISSIONS } from '../Constants'
import './Calendar.scss'

interface ICalendarMountProps {
  days: Day[]
  selectedClient: string
  onPostClick: (postId: string) => void
}

interface ICalendarProps {
  history: any
}

export interface ICalendarState {
  isClient: boolean
  companyName: string
  currentMonth: number
  currentYear: number
  selectedClient: string
  selectedPost?: Post,
  selectedPostId?: string,
  selectedPostFileName?: string,
  selectedPostFileUrl?: string,
  selectedPostFileContentType?: string,
  pendingPosts: Post[],
  pendingClients: IPendingClient,
  pendingRef?: DatabaseQuery
}

type Day = {
  value: number
  date: string
  classesNames: string
}

function CalendarMonth(props: ICalendarMountProps) {
  return (
    <div className="calendar-month">
      {props.days.map((day, key) => {
        return (
          <div id={day.date} key={key} className={day.classesNames}>
            <div className="day-number">{day.value}</div>
            <div className="calendar-posts">
              <CalendarPosts date={day.date} onPostClick={props.onPostClick} selectedClient={props.selectedClient} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default class Calendar extends React.Component<ICalendarProps, ICalendarState> {
  static contextType = AppContext
  today = new Date()
  loaded = false
  postManager?: PostManager
  clientManager?: ClientManager

  constructor(props: ICalendarProps) {
    super(props)
    this.state = {
      isClient: true,
      companyName: '',
      currentMonth: this.today.getMonth(),
      currentYear: this.today.getFullYear(),
      selectedClient: AppContextService.getSelectedClient(),
      pendingPosts: [],
      pendingClients: {}
    }
  }

  componentDidMount() {
    if (this.context.currentUser) {
      this.init()
    }
  }

  componentDidUpdate() {
    if (!this.loaded && this.context.currentUser) {
      this.init()
    }
  }

  componentWillUnmount() {
    if (this.state.pendingRef)
      DatabaseService.unMountListening(this.state.pendingRef)
  }


  init() {
    const isClient = this.context.userPermission === USER_PERMISSIONS.CLIENT
    this.setState({ isClient })
    this.loaded = true

    if (isClient) {
      this.postManager = new PostManager(this.context)
      this.clientManager = new ClientManager(this.context)

      this.postManager.getPending((pendingPosts, pendingRef) => this.setState({ pendingPosts, pendingRef }))
      this.clientManager.get(client => this.setState({ companyName: client!.companyName, selectedClient: this.context.currentUser.uid }))
    } else {
      PostManager.getPendingAllClients((pendingClients, pendingRef) => this.setState({
        pendingClients, pendingRef, pendingPosts: pendingClients[this.state.selectedClient] || []
      }))

      if (this.state.selectedClient) {
        this.postManager = new PostManager(this.context, this.state.selectedClient)
      }
    }
  }

  setSelectClient = (selectedClient: string) => {
    this.setState({ selectedClient, pendingPosts: this.state.pendingClients[selectedClient] || [] })
    AppContextService.setSelectedClient(selectedClient)
    this.postManager = new PostManager(this.context, selectedClient)
  }

  setPreviousMonth = () => {
    let previousMonth = this.state.currentMonth - 1
    if (previousMonth === -1) {
      previousMonth = 11
      this.setState({ currentYear: this.state.currentYear - 1 })
    }
    this.setState({ currentMonth: previousMonth })
  }

  setNextMonth = () => {
    let nextMonth = this.state.currentMonth + 1
    if (nextMonth === 12) {
      nextMonth = 0
      this.setState({ currentYear: this.state.currentYear + 1 })
    }
    this.setState({ currentMonth: nextMonth })
  }

  getDays = () => {
    const dayObj = new Date(this.state.currentYear, this.state.currentMonth, 1)
    const days = []
    const getDayObj = (additionalClasses: string = '') => {
      const day = dayObj.getDate()
      const date = format(dayObj, 'dd/MM/yyyy')
      const classesNames = 'calendar-day ' + additionalClasses
      return { value: day, date, classesNames } as Day
    }

    //PREVIOUS MONTH DAYS
    dayObj.setDate(-(dayObj.getDay() - 1))
    const previousMonth = this.state.currentMonth === 0 ? 11 : this.state.currentMonth - 1
    while (dayObj.getMonth() === previousMonth) {
      days.push(getDayObj('inactive'))
      dayObj.setDate(dayObj.getDate() + 1)
    }

    //CURRENT MONTH DAYS
    while (dayObj.getMonth() === this.state.currentMonth) {
      days.push(getDayObj())
      dayObj.setDate(dayObj.getDate() + 1)
    }
    //NEXT MONTH DAYS
    for (let i = days.length; i < 42; i++) {
      days.push(getDayObj('inactive'))
      dayObj.setDate(dayObj.getDate() + 1)
    }

    return days;
  }

  getSelectedPost = (id: string) => this.postManager!.get(id, post => {
    this.setState({ selectedPost: post!, selectedPostId: id })

    if (post!.postFiles && post!.postFiles.length) {
      for (let file of post!.postFiles) {
        PostManager.getFile(file.fullPath, url => this.setState({
          selectedPostFileName: file.name,
          selectedPostFileUrl: url,
          selectedPostFileContentType: file.contentType
        }))
      }
    } else {
      this.setState({ selectedPostFileUrl: undefined, selectedPostFileContentType: undefined })
    }
  })

  render() {
    return (
      <div className="container-fluid p-0">
        <SidebarMenu activeMenu={MENU_ITEMS.CALENDAR} />
        <Header>Calendário</Header>

        <div className="row calendar">
          <main className="offset-1 col-7 p-0 calendar-main">

            <div className="calendar-header">
              <Div if={this.state.isClient} className="username">{this.state.companyName}</Div>
              <ClientSelect
                if={!this.state.isClient}
                selectedClient={this.state.selectedClient}
                onSelectClient={this.setSelectClient}
                pendingClients={this.state.pendingClients} />
              <Div if={this.state.pendingPosts.length > 0} className="notifications">
                <i className="material-icons">notification_important</i> Você tem {this.state.pendingPosts.length} postagens pendentes de revisão
              </Div>
            </div>

            <div className="calendar-month-header">
              {DAYS_NAMES.map((dayName, key) => <div className='calendar-day-header' key={key}>{dayName}</div>)}
            </div>

            <CalendarMonth days={this.getDays()} onPostClick={this.getSelectedPost} selectedClient={this.state.selectedClient} />

            <div className="calendar-footer">
              <span className="material-icons" onClick={this.setPreviousMonth}>keyboard_arrow_left</span>
              <div className="calendar-footer-title">{Constants.getValue(MONTHS, this.state.currentMonth.toString())} {this.state.currentYear}</div>
              <span className="material-icons" onClick={this.setNextMonth}>keyboard_arrow_right</span>
            </div>

          </main>

          <div className="col-1 calendar-divisor">
            <div className="divisor"></div>
          </div>

          <PostPreview state={this.state} onChangeState={state => this.setState(state)} postManager={this.postManager} />

        </div>
        <BackdropLoading loading={!this.loaded} />
      </div>
    )
  }
}