import Template from './Template';
import DatabaseService from '../services/DatabaseService';
import { IAppContext } from '../services/AppContextService';
import { DATABASE_REFS } from '../Constants';

//MODEL
export class Client extends Template {
  address: string = ''
  cellphone: string = ''
  city: string = ''
  companyId: string = ''
  companyName: string = ''
  complement: string = ''
  email: string = ''
  name: string = ''
  phone: string = ''
  postalCode: string = ''
  state: string = ''
  whatsapp: string = ''

  plan: string = ''
  category: string = ''
  payment: string = ''
}

type ClientList = {
  [uid: string]: Client
}

export default class ClientManager {

  private context: IAppContext;

  constructor(context: IAppContext) {
    this.context = context
  }

  //PUBLIC METHODS

  get = (callback: (client: Client) => void) => {
    if (this.context.currentUser) {
      ClientManager.get(this.context.currentUser.uid, callback)
    }
  }

  save = (client: Client, onFinish: () => void) => {
    if (this.context.currentUser) {
      ClientManager.save(client, this.context.currentUser.uid, onFinish)
    }
  }

  static save = (client: Client, uid: string, onSucess?: () => void, onError?: (error: Error) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.CLIENTS, uid)
    DatabaseService.save(ref, client, onSucess, onError)
  }

  static get = (uid: string, callback: (client: Client) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.CLIENTS, uid)
    DatabaseService.get(ref, callback)
  }

  static getAll = (callback: (clients: ClientList) => void) => {
    const ref = DatabaseService.createRef(DATABASE_REFS.CLIENTS)
    DatabaseService.get(ref, callback)
  }
}