import React from 'react'
import { FirebaseUser } from "./AuthService";
import { LOCAL_STORAGE_KEYS } from '../Constants';


export interface IAppContext {
  isLogged: boolean
  currentUser: FirebaseUser | null
  userPermission: string
  profilePictureUrl: string
}

export const AppContext = React.createContext<IAppContext>({
  isLogged: false,
  currentUser: null,
  userPermission: '',
  profilePictureUrl: ''
})

const setSelectedClient = (clientUid: string) => sessionStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_CLIENT, clientUid)
const getSelectedClient = () => sessionStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_CLIENT) || ''

export default { setSelectedClient, getSelectedClient }