import React from 'react'
import './Input.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerclass?: string
  label: string
}

export default function Input(props: InputProps) {
  return (
    <div className={'input-component ' + (props.containerclass || '')}>
      <input placeholder=" " {...props} />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  )
}