import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBXyy1m7nkZhRHUhRO7pBhW7N8Rqd9tKVk",
  authDomain: "petra-digital-prod.firebaseapp.com",
  databaseURL: "https://petra-digital-prod.firebaseio.com",
  projectId: "petra-digital-prod",
  storageBucket: "petra-digital-prod.appspot.com",
  messagingSenderId: "746687628450",
  appId: "1:746687628450:web:64749e7ddcd921f04083fb",
  measurementId: "G-YC1W8JV0E1"
};

firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export default firebase