import React, { useEffect } from 'react'
import jquery from 'jquery';
import 'jquery-textcomplete'
import 'emojionearea'

import 'emojionearea/dist/emojionearea.min.css'
import './EmojiArea.scss'

interface IEmojiAreaProps {
  loadFunction: (func: (text?: string) => void) => void
  onChange: (text: string) => void
}

export default function EmojiArea(props: IEmojiAreaProps) {
  const $: any = jquery

  const loadEmojiArea = (text?: string) => {
    $('#emoji-textarea').val(text).emojioneArea({
      pickerPosition: "left",
      tonesStyle: "bullet",
      searchPlaceholder: "Busca (em inglês)",
      buttonTitle: "Use a tecla TAB para inserir emoji mais rápido",
      filters: {
        recent: { title: "Recente" },
        smileys_people: { title: "Sorrisos & Pessoas" },
        animals_nature: { title: "Animais & Natureza" },
        food_drink: { title: "Comidas & Bebidas" },
        activity: { title: "Atividades" },
        travel_places: { title: "Viagem & Lugares" },
        objects: { title: "Objetos" },
        symbols: { title: "Símbolos" },
        flags: {
          icon: "flag_br",
          title: "Bandeiras"
        }
      },
      events: {
        keyup: function () {
          const self = this as any
          props.onChange(self.getText())
        },
        emojibtn_click: function () {
          const self = this as any
          props.onChange(self.getText())
        }
      }
    })
  }

  const emitLoadFunction = () => props.loadFunction(loadEmojiArea)

  useEffect(emitLoadFunction, [])

  return <textarea id="emoji-textarea" className="text-area" placeholder="Digite aqui o texto do post"></textarea>
}