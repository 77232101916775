import FirebaseService from './FirebaseService'
import ErrorService from './ErrorService'

export type DatabaseReference = FirebaseService.database.Reference
export type DatabaseQuery = FirebaseService.database.Query
export type DatabaseSnapshot = FirebaseService.database.DataSnapshot

const FirabaseDatabase = FirebaseService.database()

//METHODS
const createRef = (...stringRef: string[]) => FirabaseDatabase.ref(stringRef.join('/'))

const createStringRef = (...strings: string[]) => strings.join('/')

const get = (ref: DatabaseReference | DatabaseQuery, successfulCallback: (value: any) => void, failCallback?: (error: Error) => void) =>
  ref.once('value', (snapshot) => successfulCallback(snapshot.val()), failCallback ? failCallback : ErrorService.setError)

const getWithListen = (ref: DatabaseReference | DatabaseQuery, successfulCallback: (value: any) => void, failCallback?: (error: Error) => void) =>
  ref.on('value', (snapshot) => successfulCallback(snapshot.val()), failCallback ? failCallback : ErrorService.setError)

const save = (ref: DatabaseReference, data: any, successfulCallback?: () => void, failCallback?: (error: Error) => void) =>
  ref.update(data, (error: Error | null) => {
    if (error)
      failCallback ? failCallback(error) : ErrorService.setError(error)
    else if (successfulCallback)
      successfulCallback()
  })

const push = (ref: DatabaseReference, data: any, onFinish?: (error: Error | null) => void) =>
  ref.push(data, (error: Error | null) => onFinish ? onFinish(error) : ErrorService.setError(error))

const getPushKey = (ref: DatabaseReference) => ref.push().key

const unMountListening = (ref: DatabaseQuery) => ref.off()

const remove = (ref: DatabaseReference, onFinish?: (error: Error | null) => void) => ref.remove(onFinish)

export default { createRef, createStringRef, get, getWithListen, save, push, getPushKey, unMountListening, remove }