import React from 'react'
import { useHistory } from 'react-router';
import swal from 'sweetalert';
import { ICalendarState } from '../../pages/Calendar'
import Div from '../utils/Div';
import MediaImage from '../utils/MediaImage';
import PreviewFile from '../utils/PreviewFile';
import PostManager from '../../managers/PostManager'
import Constants, { MEDIAS_LABELS, POST_STATUS, POST_STATUS_LABELS, ROUTES } from '../../Constants';
import './PostPreview.scss'

interface IPostPreviewProps {
  state: ICalendarState
  onChangeState: (state: ICalendarState) => void
  postManager?: PostManager
}

export default function PostPreview(props: IPostPreviewProps) {
  const history = useHistory()

  const changeStatus = (status: string) => {
    const selectedPost = props.state.selectedPost
    selectedPost!.postStatus = status
    props.postManager!.save(props.state.selectedPostId!, selectedPost!, () => props.onChangeState({ selectedPost } as ICalendarState))
  }

  const approvePost = () => {
    swal({
      title: 'Tem certeza que deseja APROVAR esta publicação?',
      icon: "warning",
      buttons: ["NÃO", "SIM"]
    }).then(value => {
      if (value)
        changeStatus(POST_STATUS.APPROVED)
    })
  }

  const denyPost = () => {
    swal({
      title: 'Tem certeza que deseja RECUSAR esta publicação?',
      icon: "warning",
      buttons: ["NÃO", "SIM"]
    }).then(value => {
      if (value)
        changeStatus(POST_STATUS.DENIED)
    })
  }

  const removePost = () => {
    swal({
      title: 'Tem certeza que deseja EXCLUIR esta publicação?',
      icon: "warning",
      buttons: ["NÃO", "SIM"]
    }).then(value => {
      if (value)
        props.postManager!.remove(props.state.selectedPostId!, () => props.onChangeState({ selectedPost: undefined } as ICalendarState))
    })
  }

  const goToEditPost = () => history.push(ROUTES.STUDIO, { postId: props.state.selectedPostId })

  const getPostText = () => {
    if (props.state.selectedPost) {
      return props.state.selectedPost.text.replaceAll('\n', '<br />')
    }
    return ''
  }

  const downloadFile = () => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(xhr.response)
      link.download = props.state.selectedPostFileName!
      document.body.appendChild(link)
      link.click();
      document.body.removeChild(link)
    }
    xhr.open('GET', props.state.selectedPostFileUrl!)
    xhr.send()
  }

  return (
    <Div if={!!props.state.selectedPost} className="col post-preview">
      <div className="post-preview-main">
        <div className={`post-preview-header post-${props.state.selectedPost?.postStatus?.toLowerCase()}`}>{Constants.getValue(POST_STATUS_LABELS, props.state.selectedPost?.postStatus)}</div>
        <div className="post-preview-item">
          <div className="account">
            <div className="account-image"><MediaImage mediaKey={props.state.selectedPost?.account} /></div>
            <div className="account-info">{props.state.selectedPost?.username}<br />{Constants.getValue(MEDIAS_LABELS, props.state.selectedPost?.account)}</div>
          </div>

          <div className="preview-file-container">
            <PreviewFile url={props.state.selectedPostFileUrl} contentType={props.state.selectedPostFileContentType} />
            <Div if={!!props.state.selectedPostFileUrl} className="preview-file-icon" onClick={downloadFile}>
              <i className="material-icons" title="Baixar arquivo">get_app</i>
            </Div>
          </div>

          <div className="text" dangerouslySetInnerHTML={{ __html: getPostText() }}></div>
          <div className="buttons">
            <Div if={!props.state.isClient && props.state.selectedPost?.postStatus !== POST_STATUS.PUBLISHED}
              onClick={goToEditPost}
              className="button-review">Revisar</Div>

            <Div if={!props.state.isClient && props.state.selectedPost?.postStatus !== POST_STATUS.PUBLISHED}
              onClick={removePost}
              className="button-denied">Excluir</Div>

            <Div if={props.state.isClient && props.state.selectedPost?.postStatus === POST_STATUS.REVIEW} onClick={approvePost} className="button-approved">Aprovar</Div>

            <Div if={props.state.isClient && props.state.selectedPost?.postStatus === POST_STATUS.REVIEW} onClick={denyPost} className="button-denied">Não Publicar</Div>

            <Div if={!props.state.isClient && props.state.selectedPost?.postStatus === POST_STATUS.APPROVED} onClick={() => changeStatus(POST_STATUS.PUBLISHED)} className="button-published">Publicado</Div>
          </div>
        </div>
      </div>
    </Div>
  )
}
