import React, { useState, useEffect } from 'react'
import ProgressCircle from './ProgressCircle'
import './ProfilePicture.scss'

interface IProfilePictureProps {
  profilePictureUrl?: string
  className?: string
}

export default function ProfilePicture(props: IProfilePictureProps) {
  const getClassName = (currentClass: string) => props.className ? `${currentClass} ${props.className}` : currentClass
  const defaultProfilePicture = <i className={getClassName('material-icons')}>account_circle</i>
  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture)

  const loadProfilePicture = () => {
    if (props.profilePictureUrl) {
      setProfilePicture(<ProgressCircle />)
      const image = new Image()
      image.onload = () => {
        const picture = <div className={getClassName('picture')} style={{ backgroundImage: `url('${image.src}')` }}></div>
        setProfilePicture(picture)
      }
      image.src = props.profilePictureUrl
    }
  }

  useEffect(loadProfilePicture, [props.profilePictureUrl])

  return <div className="profile-picture">{profilePicture}</div>
}