import React from 'react';
import MaskedInput from 'react-text-mask'
import AuthService from '../services/AuthService';
import Header from '../components/utils/Header';
import Input from '../components/utils/Input';
import UserManager, { User } from '../managers/UserManager';
import ClientManager, { Client } from '../managers/ClientManager';
import MediaAccountManager, { MediaAccount } from '../managers/MediaAccountManager';
import { ROUTES, EXCEPTION_ERRORS, MEDIAS_KEYS, MASKS } from '../Constants';
import './CreateAccount.scss'

import logo from '../assets/images/Logo_Petra.svg'
import Container from '../components/utils/Container';
// import google from '../assets/images/Google_Icon.svg'
// import facebook from '../assets/images/Facebook_Icon_Color.svg'

interface ICreateAccountProps {
  history: any
}

interface ICreateAccountState {
  companyIdMask: (string | RegExp)[]
  errorCode: string
}

export default class CreateAccount extends React.Component<ICreateAccountProps, ICreateAccountState> {
  constructor(props: ICreateAccountProps) {
    super(props)

    this.state = {
      companyIdMask: MASKS.COMPANY_ID.CPF,
      errorCode: ''
    }
  }

  componentDidMount() {
    const email = document.getElementById('inp_email') as HTMLInputElement
    const confirmEmail = document.getElementById('inp_confirm_email') as HTMLInputElement
    const password = document.getElementById('inp_password') as HTMLInputElement
    const confirmPassword = document.getElementById('inp_confirm_password') as HTMLInputElement

    const addConfirmValidation = (mainInput: HTMLInputElement, confirmInput: HTMLInputElement, text: string) => {
      for (let targetInput of [mainInput, confirmInput]) {
        targetInput.addEventListener('input', () => {
          if (mainInput.value !== confirmInput.value) {
            confirmInput.setCustomValidity(text)
          } else {
            confirmInput.setCustomValidity('')
          }
        })
      }
    }

    addConfirmValidation(email, confirmEmail, 'Confirmação de e-mail incorreta!')
    addConfirmValidation(password, confirmPassword, 'Confirmação de senha incorreta!')
  }

  changeCompanyIdMask = () => {
    const companyIdInput = document.getElementById("inp_company_id") as HTMLInputElement
    if (companyIdInput.value.length < 14) {
      this.setState({ companyIdMask: MASKS.COMPANY_ID.CPF })
    } else {
      this.setState({ companyIdMask: MASKS.COMPANY_ID.CNPJ })
    }
  }

  login = () => {
    this.props.history.push(ROUTES.LOGIN)
  }

  createAccount = () => {
    const createAccounts = (uid: string, name: string) => {
      const instagram = new MediaAccount()
      const facebook = new MediaAccount()
      instagram.username = '@' + name
      instagram.order = 1
      facebook.username = '@' + name
      facebook.order = 2

      MediaAccountManager.save(uid, MEDIAS_KEYS.INSTAGRAM_FEED, instagram, () => {
        MediaAccountManager.save(uid, MEDIAS_KEYS.FACEBOOK_FEED, facebook, () => {
          this.props.history.push(ROUTES.COMPLETE_ACCOUNT)
        })
      })
    }

    if (document.forms[0].checkValidity()) {
      const client = new Client()
      client.companyName = (document.getElementById("inp_company_name") as HTMLInputElement).value
      client.name = (document.getElementById("inp_name") as HTMLInputElement).value
      client.companyId = (document.getElementById("inp_company_id") as HTMLInputElement).value
      client.address = (document.getElementById("inp_address") as HTMLInputElement).value
      client.postalCode = (document.getElementById("inp_postal_code") as HTMLInputElement).value
      client.city = (document.getElementById("inp_city") as HTMLInputElement).value
      client.state = (document.getElementById("inp_state") as HTMLInputElement).value
      client.complement = (document.getElementById("inp_complement") as HTMLInputElement).value
      client.phone = (document.getElementById("inp_phone") as HTMLInputElement).value
      client.cellphone = (document.getElementById("inp_cellphone") as HTMLInputElement).value
      client.whatsapp = (document.getElementById("inp_whatsapp") as HTMLInputElement).value
      client.email = (document.getElementById("inp_email") as HTMLInputElement).value

      const user = new User()
      user.name = client.companyName
      user.email = client.email

      const password = document.getElementById("inp_password") as HTMLInputElement
      AuthService.createAccount(client.email, password.value)
        .then(credential => {
          ClientManager.save(client, credential.user!.uid,
            () => UserManager.save(user, credential.user!.uid,
              () => createAccounts(credential.user!.uid, client.companyName),
              () => this.setState({ errorCode: EXCEPTION_ERRORS.SAVE_USER })),
            () => this.setState({ errorCode: EXCEPTION_ERRORS.SAVE_CLIENT }))
        })
        .catch(error => this.setState({ errorCode: error.code }))
    }
  }

  render() {
    return (
      <Container errorCode={this.state.errorCode} onCloseError={() => this.setState({ errorCode: '' })}>
        <div className="row create-account">
          <aside className="col-4 create-account-aside">
            <img src={logo} alt="logotipo petros" className="logo" />
            <button onClick={this.login} className="primary-button login-button">Entrar</button>
          </aside>

          <div className="col p-0">
            <Header hideLogout={true}>Crie sua conta</Header>
            <form name="createAccountForm" className="create-account-main" onSubmit={event => event.preventDefault()}>
              {/* <div className="login-methods">
              <img src={google} alt="google logo" />
              <img src={facebook} alt="facebook logo" />
            </div> */}
              <div className="create-account-form">
                <Input id="inp_company_name" name="company_name" type="text" containerclass="span-6" label="Nome Fantasia:" required />
                <Input id="inp_name" name="name" type="text" containerclass="span-6" label="Razão Social:" required />

                <div className="input-component span-6">
                  <MaskedInput id="inp_company_id" name="company_id" mask={this.state.companyIdMask} guide={false} onKeyPress={this.changeCompanyIdMask} type="text" placeholder=" " required />
                  <label htmlFor="inp_company_id">CPF ou CNPJ:</label>
                </div>

                <Input id="inp_address" name="address" type="text" containerclass="span-4" label="Endereço:" />
                <Input id="inp_postal_code" name="postal_code" type="text" containerclass="span-2" label="CEP ou Código Postal:" />
                <Input id="inp_city" name="city" type="text" containerclass="span-2" label="Cidade:" />
                <Input id="inp_state" name="state" type="text" containerclass="span-2" label="Estado:" />
                <Input id="inp_complement" name="complement" containerclass="span-2" label="Complemento:" />

                <div className="input-component span-2">
                  <MaskedInput id="inp_phone" name="phone" type="text" mask={MASKS.PHONE} guide={false} placeholder=" " />
                  <label htmlFor="inp_phone">Telefone Fixo:</label>
                </div>
                <div className="input-component span-2">
                  <MaskedInput id="inp_cellphone" name="cellphone" type="text" mask={MASKS.CELLPHONE} guide={false} placeholder=" " />
                  <label htmlFor="inp_cellphone">Telefone Móvel:</label>
                </div>
                <div className="input-component span-2">
                  <MaskedInput id="inp_whatsapp" name="whatsapp" type="text" mask={MASKS.CELLPHONE} guide={false} placeholder=" " />
                  <label htmlFor="inp_whatsapp">Whatsapp:</label>
                </div>

                <Input id="inp_email" name="email" type="email" containerclass="span-3" label="Email:" required />
                <Input id="inp_confirm_email" name="confirm_email" type="email" autoComplete="off" containerclass="span-3" label="Confirme o Email:" required />
                <Input id="inp_password" name="password" type="password" minLength={6} autoComplete="off" containerclass="span-3" label="Senha:" required />
                <Input id="inp_confirm_password" name="confirm_password" type="password" minLength={6} autoComplete="off" containerclass="span-3"
                  label="Confirme a Senha:" required />
              </div>
              <div className="button-box">
                <button onClick={this.createAccount} className="primary-button create-account-button">Criar conta</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    )
  }
}