import React from 'react'
import MaskedInput from 'react-text-mask'
import swal from 'sweetalert'
import Dialog from '@material-ui/core/Dialog'
import SidebarMenu from '../components/utils/SidebarMenu'
import Header from '../components/utils/Header'
import Input from '../components/utils/Input'
import BackdropLoading from '../components/utils/BackdropLoading'
import Div from '../components/utils/Div'
import ProgressCircle from '../components/utils/ProgressCircle'
import ClientManager, { Client } from '../managers/ClientManager'
import UserManager, { User } from '../managers/UserManager'
import AuthService from '../services/AuthService'
import { AppContext } from '../services/AppContextService'
import Constants, { MENU_ITEMS, MASKS, FILE_TYPES, EXCEPTION_ERRORS, USER_PERMISSIONS } from '../Constants'
import './Profile.scss'
import '../components/utils/Dialog.scss'

interface ProfileProps {

}

interface ProfileState {
  client: Client
  user: User
  inputHover: boolean
  userPictureUrl: string
  selectedPicture: File | null
  finishModalShow: boolean
  reAuthModalShow: boolean
  reAuthCallback: () => void
  uploadProgress: number
  loading: boolean
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {
  static contextType = AppContext
  isClient: boolean = true
  clientManager?: ClientManager
  userManager?: UserManager

  constructor(props: ProfileProps) {
    super(props)

    this.state = {
      client: new Client(),
      user: new User(),
      inputHover: false,
      userPictureUrl: '',
      selectedPicture: null,
      finishModalShow: false,
      reAuthModalShow: false,
      reAuthCallback: () => { },
      uploadProgress: 0,
      loading: true
    }
  }

  componentDidMount() {
    this.loadData()

    const email = document.getElementById('inp_email') as HTMLInputElement
    const confirmEmail = document.getElementById('inp_confirm_email') as HTMLInputElement
    const password = document.getElementById('inp_password') as HTMLInputElement
    const confirmPassword = document.getElementById('inp_confirm_password') as HTMLInputElement

    const addConfirmValidation = (mainInput: HTMLInputElement, confirmInput: HTMLInputElement, text: string) => {
      for (let targetInput of [mainInput, confirmInput]) {
        targetInput.addEventListener('input', () => {
          if (mainInput.value !== confirmInput.value) {
            confirmInput.setCustomValidity(text)
          } else {
            confirmInput.setCustomValidity('')
          }
        })
      }
    }

    addConfirmValidation(email, confirmEmail, 'Confirmação de e-mail incorreta!')
    addConfirmValidation(password, confirmPassword, 'Confirmação de senha incorreta!')
  }

  componentDidUpdate() {
    this.loadData()
  }

  loadData = () => {
    if (!this.userManager && this.context.currentUser) {
      this.isClient = this.context.userPermission === USER_PERMISSIONS.CLIENT
      if (this.isClient) {
        this.clientManager = new ClientManager(this.context)
        this.clientManager.get(client => this.setState({ client }))
      }

      this.userManager = new UserManager(this.context)
      this.userManager.get(user => {
        this.setState({ user })
        if (user.profilePicture) {
          UserManager.getUserPicture(user.profilePicture.fullPath, this.loadImage)
        } else {
          this.setState({ loading: false })
        }
      })
    }
  }

  uploadImage = (callback?: () => void) => {
    this.userManager!.uploadUserPicture(this.state.selectedPicture!, uploadProgress => {
      if (uploadProgress < 100)
        this.setState({ uploadProgress })
    }, file => {
      const user = this.state.user
      user.profilePicture = file
      this.userManager!.save(user, callback)
    })
  }

  finishUpload = () => this.setState({ uploadProgress: 100 })
  saveClient = () => this.clientManager!.save(this.state.client, this.finishUpload)
  saveUser = () => this.userManager!.save(this.state.user, this.finishUpload)

  saveClientData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (this.clientManager) {
      this.setState({ finishModalShow: true })

      if (this.state.selectedPicture) {
        if (this.state.user.profilePicture) {
          UserManager.removeUserPicture(this.state.user.profilePicture.fullPath, () => this.uploadImage(this.saveClient))
        } else {
          this.uploadImage(this.saveClient)
        }
      } else {
        this.saveClient()
      }
    }
  }

  saveUserData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (this.userManager) {
      this.setState({ finishModalShow: true })

      if (this.state.selectedPicture) {
        if (this.state.user.profilePicture) {
          UserManager.removeUserPicture(this.state.user.profilePicture.fullPath, () => this.uploadImage(this.finishUpload))
        } else {
          this.uploadImage(this.finishUpload)
        }
      } else {
        this.saveUser()
      }
    }
  }

  setClientValue = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const client = Object.assign(this.state.client)
    client[key] = event.target.value
    this.setState({ client })
  }

  setUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const user = this.state.user
    user.name = event.target.value
    this.setState({ user })
  }

  getImage = () => {
    if (this.state.inputHover)
      return (
        <div className="add-image">
          <i className="material-icons">add_photo_alternate</i>
          <span>Trocar Imagem</span>
        </div>
      )
    else if (this.state.userPictureUrl)
      return ''
    return <i className="material-icons">account_circle</i>
  }

  loadImage = (userPictureUrl: string) => {
    const container = document.getElementById('profile-picture')!
    container.style.backgroundImage = `url('${userPictureUrl}')`
    this.setState({ userPictureUrl, loading: false })
  }

  selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files && files[0]) {
      const file = files[0]
      this.setState({ selectedPicture: file })

      if (file.type === FILE_TYPES.JPG || file.type === FILE_TYPES.PNG) {
        if (this.state.userPictureUrl) {
          URL.revokeObjectURL(this.state.userPictureUrl)
        }
        const userPictureUrl = URL.createObjectURL(file)
        this.loadImage(userPictureUrl)
      } else {
        swal(file.type + ' não é um formato aceito', '', 'error')
        return
      }
    }
  }

  changeEmail = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event)
      event.preventDefault()
    this.setState({ loading: true })

    const userManager = new UserManager(this.context)
    const email = document.getElementById('inp_email') as HTMLInputElement
    userManager.changeEmail(email.value,
      () => this.changeOk('E-mail alterado com sucesso!'),
      error => this.changeError(error, this.changeEmail))
  }

  changePassword = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event)
      event.preventDefault()
    this.setState({ loading: true })

    const password = document.getElementById('inp_password') as HTMLInputElement
    AuthService.updatePassword(password.value,
      () => this.changeOk('Senha alterada com sucesso!'),
      error => this.changeError(error, this.changePassword))
  }

  changeOk = (message: string) => {
    this.setState({ loading: false })
    swal(message, '', 'success')
  }

  changeError = (error: any, reAuthCallback: () => void) => {
    this.setState({ loading: false })
    if (error.code === 'auth/requires-recent-login') {
      this.setState({ reAuthModalShow: true, reAuthCallback })
    } else {
      swal('Ocorreu um erro!', Constants.getValue(EXCEPTION_ERRORS, error.code), 'error')
    }
  }

  reauthenticate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const email = document.getElementById('inp_email_reauth') as HTMLInputElement
    const password = document.getElementById('inp_password_reauth') as HTMLInputElement

    AuthService.reauthenticateWithEmail(email.value, password.value,
      () => {
        this.setState({ reAuthModalShow: false })
        this.state.reAuthCallback()
      },
      error => swal('Ocorreu um erro!', Constants.getValue(EXCEPTION_ERRORS, error.code), 'error')
    )
  }

  render() {
    return (
      <div className="container-fluid p-0">
        <SidebarMenu activeMenu={MENU_ITEMS.PROFILE} />
        <Header>Perfil</Header>

        <div className="row profile">
          <main className="offset-1 p-0 col-10">
            <div className="header-title">Editar seus Dados Cadastrais</div>

            <div className="profile-container">
              <div id="profile-picture" className="profile-picture">
                <input type="file" accept=".jpg,.png" onChange={this.selectImage}
                  onMouseOver={() => this.setState({ inputHover: true })} onMouseOut={() => this.setState({ inputHover: false })} />
                {this.getImage()}
              </div>

              <Div if={this.isClient}>
                <form onSubmit={this.saveClientData}>
                  <div className="profile-form">
                    <Input id="inp_company_name" name="company_name" value={this.state.client.companyName} onChange={event => this.setClientValue(event, 'companyName')} type="text" containerclass="span-6" label="Nome Fantasia:" required />
                    <Input id="inp_address" name="address" type="text" value={this.state.client.address} onChange={event => this.setClientValue(event, 'address')} containerclass="span-4" label="Endereço:" />
                    <Input id="inp_postal_code" name="postal_code" value={this.state.client.postalCode} onChange={event => this.setClientValue(event, 'postalCode')} type="text" containerclass="span-2" label="CEP ou Código Postal:" />
                    <Input id="inp_city" name="city" value={this.state.client.city} onChange={event => this.setClientValue(event, 'city')} type="text" containerclass="span-2" label="Cidade:" />
                    <Input id="inp_state" name="state" value={this.state.client.state} onChange={event => this.setClientValue(event, 'state')} type="text" containerclass="span-2" label="Estado:" />
                    <Input id="inp_complement" name="complement" value={this.state.client.complement} onChange={event => this.setClientValue(event, 'complement')} containerclass="span-2" label="Complemento:" />

                    <div className="input-component span-2">
                      <MaskedInput id="inp_phone" name="phone" value={this.state.client.phone} onChange={event => this.setClientValue(event, 'phone')} type="text" mask={MASKS.PHONE} guide={false} placeholder=" " />
                      <label htmlFor="inp_phone">Telefone Fixo:</label>
                    </div>
                    <div className="input-component span-2">
                      <MaskedInput id="inp_cellphone" name="cellphone" value={this.state.client.cellphone} onChange={event => this.setClientValue(event, 'cellphone')} type="text" mask={MASKS.CELLPHONE} guide={false} placeholder=" " />
                      <label htmlFor="inp_cellphone">Telefone Móvel:</label>
                    </div>
                    <div className="input-component span-2">
                      <MaskedInput id="inp_whatsapp" name="whatsapp" value={this.state.client.whatsapp} onChange={event => this.setClientValue(event, 'whatsapp')} type="text" mask={MASKS.CELLPHONE} guide={false} placeholder=" " />
                      <label htmlFor="inp_whatsapp">Whatsapp:</label>
                    </div>
                  </div>
                  <div className="save-button">
                    <button type="submit" className="primary-button">Salvar</button>
                  </div>
                </form>
              </Div>

              <Div if={!this.isClient}>
                <form onSubmit={this.saveUserData}>
                  <div className="profile-form">
                    <Input id="inp_user_name" name="user_name" value={this.state.user.name} onChange={event => this.setUserName(event)} type="text" containerclass="span-6" label="Nome:" required />
                  </div>
                  <div className="save-button">
                    <button type="submit" className="primary-button">Salvar</button>
                  </div>
                </form>
              </Div>

            </div>

            <div className="header-title">Editar seus Dados de Acesso</div>

            <div className="profile-container">
              <form className="profile-form" onSubmit={this.changeEmail}>
                <Input id="inp_email" name="email" type="email" containerclass="span-2" label="Novo Email:" required />
                <Input id="inp_confirm_email" name="confirm_email" type="email" autoComplete="off" containerclass="span-2" label="Confirme o Email:" required />
                <button type="submit" className="primary-button change-button span-2">Alterar</button>
              </form>

              <form className="profile-form" onSubmit={this.changePassword}>
                <Input id="inp_password" name="password" type="password" minLength={6} autoComplete="off" containerclass="span-2" label="Nova Senha:" required />
                <Input id="inp_confirm_password" name="confirm_password" type="password" minLength={6} autoComplete="off" containerclass="span-2"
                  label="Confirme a Senha:" required />
                <button className="primary-button change-button span-2">Alterar</button>
              </form>
            </div>
          </main>
        </div>
        <Dialog open={this.state.finishModalShow}>
          <div className="dialog-container">
            <div className="dialog-text dialog-text-big">
              {this.state.uploadProgress === 100 ? 'Alterações salvas com sucesso!' : 'Enviando... aguarde...'}
            </div>
            <div className="dialog-text">
              <Div if={this.state.uploadProgress !== 100}>
                <ProgressCircle value={this.state.uploadProgress} />
              </Div>
              <Div if={this.state.uploadProgress === 100} className="swal-icon swal-icon--success">
                <span className="swal-icon--success__line swal-icon--success__line--long"></span>
                <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

                <div className="swal-icon--success__ring"></div>
                <div className="swal-icon--success__hide-corners"></div>
              </Div>
            </div>
            <div className="dialog-buttons">
              <button className="primary-button" onClick={() => this.setState({ finishModalShow: false })} disabled={this.state.uploadProgress !== 100}>OK</button>
            </div>
          </div>
        </Dialog>
        <Dialog open={this.state.reAuthModalShow}>
          <div className="dialog-container large">
            <div className="dialog-text dialog-text-big">Autenticação Necessária!</div>
            <div className="dialog-text">
              <div className="profile-login">
                <div className="message">É necessário realizar a autenticação com seus dados atuais para confirmar sua identidade.</div>
                <form className="login-form" onSubmit={this.reauthenticate}>
                  <div className="input-component">
                    <label>E-mail Atual:</label>
                    <input id="inp_email_reauth" name="email" type="email" required />
                  </div>
                  <div className="input-component">
                    <label>Senha Atual:</label>
                    <input id="inp_password_reauth" name="password" type="password" minLength={6} autoComplete="off" required />
                  </div>
                  <div className="dialog-buttons">
                    <button className="primary-button" onClick={() => this.setState({ reAuthModalShow: false })}>Cancelar</button>
                    <button type="submit" className="primary-button">Confirmar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Dialog>
        <BackdropLoading loading={this.state.loading} />
      </div>
    )
  }
}