import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router'
import AuthService from '../services/AuthService';
import Div from '../components/utils/Div';
import Constants, { ROUTES, LOGIN_ERRORS } from '../Constants';
import './Login.scss'

import logo from '../assets/images/Logo_Petra.svg'
import ilustra from '../assets/images/Petronauta_Ilustra.svg'
import instagram from '../assets/images/Instagram_Icon.svg'
import facebook from '../assets/images/Facebook_Icon.svg'
import twitter from '../assets/images/Twitter_Icon.svg'
// import googleLogin from '../assets/images/Google_Icon.svg'
// import facebookLogin from '../assets/images/Facebook_Icon_Color.svg'
import loginArrow from '../assets/images/arrow_login_error.svg'
import BackdropLoading from '../components/utils/BackdropLoading';

export default function Login() {
  const history = useHistory()
  const [loginError, setLoginError] = useState('')
  const [recoverMode, setRecoverMode] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const login = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event)
      event.preventDefault()

    setLoading(true)
    const inpEmail = document.getElementById('inp_email') as HTMLInputElement
    const inpPassword = document.getElementById('inp_password') as HTMLInputElement
    const email = inpEmail.value.toLowerCase()
    const password = inpPassword.value

    AuthService.loginWithEmail(email, password,
      route => history.push(route),
      error => {
        const message = Constants.getValue(LOGIN_ERRORS, error.code) ? Constants.getValue(LOGIN_ERRORS, error.code) : LOGIN_ERRORS.INTERN_ERROR
        setLoginError(message)
      })
  }

  // const loginWithFacebook = () => {
  //   AuthService.loginWithFacebook(
  //     route => props.history.push(route),
  //     () => setLoginError(LOGIN_ERRORS.AUTH_ERROR)
  //   )
  // }

  // const loginWithGoogle = () => {
  //   AuthService.loginWithGoogle(
  //     route => props.history.push(route),
  //     () => setLoginError(LOGIN_ERRORS.AUTH_ERROR)
  //   )
  // }

  const createAccount = () => history.push(ROUTES.CREATE_ACCOUNT)

  const resetPassword = () => {
    const inpEmail = document.getElementById('inp_recover_email') as HTMLInputElement
    const email = inpEmail.value

    if (!email) {
      setLoginError(LOGIN_ERRORS.EMAIL_REQUIRED)
      inpEmail.classList.add('input-error')
      inpEmail.focus()
      return
    }

    AuthService.resetPassword(email, error => {
      if (error) {
        const message = Constants.getValue(LOGIN_ERRORS, error.code) ? Constants.getValue(LOGIN_ERRORS, error.code) : LOGIN_ERRORS.AUTH_ERROR
        setLoginError(message)
      } else {
        setEmailSent(true)
      }
    })
  }

  const backLogin = () => {
    setRecoverMode(false)
    setEmailSent(false)
  }

  const keyEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.keyCode
    if (key === 13)
      login()
  }

  useEffect(() => {
    if (loginError)
      setLoading(false)
  }, [loginError])

  return (
    <div className="container-fluid p-0">
      <header className="row login-header">
        <div className="offset-1 col-3">
          <img src={logo} alt="logotipo petros" className="logo" />
        </div>
        <div className="offset-2 col-4">
          <div className="header-title">Plataforma Petros</div>
          <div className="header-info">Gerenciamento de redes sociais</div>
        </div>
      </header>

      <main className="row login-main">
        <div className="offset-1 col-3 p-0">
          <Div show={!!loginError} className="login-error-area">
            <div className="login-error">{loginError}</div>
            <img src={loginArrow} alt="login box" />
          </Div>

          <Div if={!recoverMode} className="login-box">
            <form onSubmit={login}>
              <input id="inp_email" name="email" type="email" placeholder="E-mail" className="login-input" onChange={() => setLoginError('')} required /><br />
              <input id="inp_password" name="password" type="password" placeholder="Senha" className="login-input" onChange={() => setLoginError('')} onKeyUp={keyEnter} required /><br />
              <button className="primary-button login-button">Entrar</button><br />
            </form>
            {/* <div className="login-methods">
              <img src={googleLogin} alt="google logo" onClick={loginWithGoogle} />
              <img src={facebookLogin} alt="facebook logo" onClick={loginWithFacebook} />
            </div> */}
            <div className="forgot-password" onClick={() => setRecoverMode(true)}>Esqueceu sua senha?</div>
          </Div>

          <Div if={recoverMode} className="recover-box">
            <div className="recover-title">PARA REDEFINIR SUA SENHA SIGA OS PASSOS ABAIXO:</div>
            <div className="recover-subtitle">Digite seu <span className="highlight-text">email</span> e depois <span className="highlight-text">clique</span> em Redefinir Senha</div>
            <input id="inp_recover_email" name="email" type="email" placeholder="E-mail" className="login-input recover-input" disabled={emailSent} />
            <button onClick={resetPassword} className="primary-button login-button recover-button" disabled={emailSent}>Redefinir Senha</button>
            <Div show={emailSent} className="email-sent"><span className="highlight-text">Email enviado</span>, confira sua <span className="highlight-text">caixa de mensagens</span> para redefinir.</Div>
            <Div show={emailSent} className="send-error">Caso não consiga entre em contato com nosso atendimento.</Div>
          </Div>

          <Div if={!recoverMode} className="create-account-box">
            <button onClick={createAccount} className="primary-button">Crie sua conta</button>
          </Div>

          <Div if={recoverMode} className="back-button-box">
            <div onClick={backLogin} className="back-button">
              <span className="material-icons">arrow_back</span>
            </div>
          </Div>
        </div>
        <div className="offset-1 col-6 ilustra">
          <img src={ilustra} alt="ilustração" />
        </div>
      </main>

      <footer className="row login-footer">
        <div className="offset-1 col-1 social-networks">
          <a href="https://www.instagram.com/agpetrosdigital/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram logo" /></a>
          <a href="https://www.facebook.com/Petra-Digital-1979699188742637" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook logo" /></a>
          <a href="https://twitter.com/petrosdigital" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter logo" /></a>
        </div>
        <div className="col-2 copyright">Todos os direitos reservados - Petros Digital 2020</div>
      </footer>

      <BackdropLoading loading={loading} />
    </div>
  )
}