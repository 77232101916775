import React from 'react'
import AuthService from '../../services/AuthService'
import { useHistory } from 'react-router'
import { ROUTES } from '../../Constants'
import './Header.scss'

export interface IHeaderProps {
  children: any
  hideLogout?: boolean
}

export default function Header(props: IHeaderProps) {
  const history = useHistory()
  const logout = () => AuthService.logout(() => history.push(ROUTES.LOGIN))

  return (
    <header className="row create-account-header">
      <div className="col-1"></div>
      <div className="col p-0 header-title">{props.children}</div>
      {props.hideLogout ? <div className="col-1"></div> : (
        <div className="col-1 logout">
          <div className="logout-button" onClick={logout}>
            <i className="material-icons">exit_to_app</i>
            <span>SAIR</span>
          </div>
        </div>
      )}
    </header>
  )
}