import React from 'react'
import { registerLocale } from "react-datepicker"
import Dialog from '@material-ui/core/Dialog'
import pt from 'date-fns/locale/pt'
import { parse } from 'date-fns'

import Header from '../components/utils/Header'
import SidebarMenu from '../components/utils/SidebarMenu'
import ProgressCircle from '../components/utils/ProgressCircle'
import Div from '../components/utils/Div'
import ClientSelect from '../components/utils/ClientSelect'
import BackdropLoading from '../components/utils/BackdropLoading'
import Step1 from '../components/studio/Step1'
import Step2 from '../components/studio/Step2'
import Step3 from '../components/studio/Step3'
import Step4 from '../components/studio/Step4'
import PostManager, { Post } from '../managers/PostManager'
import MediaAccountManager, { IMediaAccount, MediaAccount } from '../managers/MediaAccountManager'
import ClientManager from '../managers/ClientManager'
import AppContextService, { AppContext } from '../services/AppContextService'
import { MENU_ITEMS, ROUTES, USER_PERMISSIONS } from '../Constants'

import 'react-datepicker/dist/react-datepicker.css'
import './../components/utils/Dialog.scss'
import './Studio.scss'

interface IStudioProps {
  location: any
  history: any
}

export interface IStudioState {
  companyName: string
  datePopover: boolean
  fileLoadedURL: string
  fileLoadedType: string
  fileLoadedName: string
  finishModalShow: boolean
  hashtagCount: number
  isClient: boolean
  loadEmojiArea: ((text?: string) => void) | null
  loadedEmojiArea: boolean
  mediaRequired: boolean
  medias: IMediaAccount
  postDate: Date | null
  postingProgress: number
  postText: string
  remainCharacters: number
  selectedClient: string
  selectedFiles: File[]
  selectedMedias: string[]
  selectedPost: Post | null
}

export interface IStepsProps {
  state: IStudioState
  onChangeState: (changes: IStudioState) => void
}

export default class Studio extends React.Component<IStudioProps, IStudioState> {
  static contextType = AppContext
  loaded = false
  postManager?: PostManager
  postId?: string

  constructor(props: IStudioProps) {
    super(props)
    this.postId = this.props.location.state?.postId

    //registering language to date picker
    registerLocale('pt', pt)

    this.state = {
      companyName: '',
      datePopover: false,
      fileLoadedURL: '',
      fileLoadedType: '',
      fileLoadedName: '',
      finishModalShow: false,
      hashtagCount: 0,
      isClient: true,
      loadEmojiArea: null,
      loadedEmojiArea: false,
      mediaRequired: false,
      medias: {},
      postDate: this.getMinDate(),
      postingProgress: 0,
      postText: '',
      remainCharacters: 2000,
      selectedClient: AppContextService.getSelectedClient(),
      selectedFiles: [],
      selectedMedias: [],
      selectedPost: null
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps: IStudioProps, prevState: IStudioState) {
    if (!this.loaded || prevState.selectedClient !== this.state.selectedClient)
      this.loadData()

    if (this.state.loadEmojiArea) {
      if (this.postId) {
        if (prevState.selectedPost?.text !== this.state.selectedPost?.text)
          this.state.loadEmojiArea(this.state.selectedPost?.text)
      } else if (prevState.loadEmojiArea !== this.state.loadEmojiArea) {
        this.state.loadEmojiArea()
      }
    }
  }

  loadData() {
    if (this.context.currentUser) {
      const isClient = this.context.userPermission === USER_PERMISSIONS.CLIENT
      this.setState({ isClient })
      this.loaded = true

      if (isClient) {
        const clientManager = new ClientManager(this.context)
        const mediaAccountManager = new MediaAccountManager(this.context)

        clientManager.get(client => this.setState({ companyName: client.companyName, selectedClient: this.context.currentUser.uid }))
        mediaAccountManager.getAll(medias => this.setState({ medias }))
        this.postManager = new PostManager(this.context)
      } else if (this.state.selectedClient) {
        this.postManager = new PostManager(this.context, this.state.selectedClient)
        AppContextService.setSelectedClient(this.state.selectedClient)

        if (this.props.location?.state?.postId) {
          this.loadPost(this.props.location.state.postId)
        } else {
          MediaAccountManager.getAll(this.state.selectedClient, medias => this.setState({ medias }))
        }
      }
    }
  }

  loadPost(postId: string) {
    this.postManager!.get(postId, selectedPost => {
      ClientManager.get(this.state.selectedClient, client => this.setState({ companyName: client.companyName }))
      if (selectedPost.postFiles) {
        for (let file of selectedPost.postFiles) {
          PostManager.getFile(file.fullPath, url => this.setState({ fileLoadedName: file.name, fileLoadedURL: url, fileLoadedType: file.contentType }))
        }
      }

      const medias: IMediaAccount = {}
      medias[selectedPost.account] = { username: selectedPost.username } as MediaAccount
      this.setPostText(selectedPost.text)

      this.setState({
        selectedPost,
        medias,
        selectedMedias: [selectedPost.account],
        postDate: parse(selectedPost.schedulingDate + selectedPost.schedulingHour, 'dd/MM/yyyyHH:mm', new Date())
      })
    })
  }

  getMinDate() {
    const cDate = new Date()
    return new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), cDate.getHours(), cDate.getMinutes() + 30)
  }

  setPostText(text: string) {
    this.setState({ postText: text })
    this.setHashtagsCount(text)
    this.setRemainCharacters(text.length)
  }

  setHashtagsCount(text: string) {
    const hashtags = text.match(/#/g)
    this.setState({ hashtagCount: hashtags ? hashtags.length : 0 })
  }

  setRemainCharacters(textLength: number) {
    const remainCharacters = 2000 - textLength
    this.setState({ remainCharacters })
  }

  redirectToCalendar = () => this.props.history.push(ROUTES.CALENDAR)

  render() {
    return (
      <div className="container-fluid p-0">
        <SidebarMenu activeMenu={MENU_ITEMS.STUDIO} />
        <Header>Estúdio</Header>

        <div className="row studio">
          <form name="studioForm" className="offset-1 col-7 p-0 studio-main" onSubmit={event => event.preventDefault()}>
            <div className="studio-header">
              <Div if={this.state.isClient || !!this.state.selectedPost}>{this.state.companyName}</Div>
              <ClientSelect
                if={!this.state.isClient && !this.state.selectedPost}
                selectedClient={this.state.selectedClient}
                onSelectClient={selectedClient => this.setState({ selectedClient })} />
              <div>Agendamento de posts</div>
            </div>

            <div className="studio-steps">
              <div>
                <Step1 state={this.state} onChangeState={changes => this.setState(changes)} />
                <Step2 state={this.state} onChangeState={changes => this.setState(changes)} />
              </div>

              <Step3 state={this.state} onChangeState={changes => this.setState(changes)} />
              <Step4
                postId={this.postId}
                postManager={this.postManager}
                setPostText={text => this.setPostText(text)}
                state={this.state}
                onChangeState={changes => this.setState(changes)} />
            </div>
          </form>

          <div className="col-1 studio-divisor">
            <div className="divisor"></div>
          </div>

          {/* <div className="col petra-academy">
            <div className="petra-academy-main">
              <div className="petra-academy-item"></div>
              <div className="petra-academy-item"></div>
              <div className="petra-academy-item"></div>
              <div className="petra-academy-item"></div>
              <div className="petra-academy-item"></div>
            </div>
          </div> */}
        </div>

        <Dialog open={this.state.finishModalShow}>
          <div className="dialog-container">
            <div className="dialog-text dialog-text-big">{this.state.postingProgress === 100 ? 'Concluído!' : 'Enviando... aguarde...'}</div>
            <div className="dialog-text">
              <Div if={this.state.postingProgress !== 100}>
                <ProgressCircle value={this.state.postingProgress} />
              </Div>
              <Div if={this.state.postingProgress === 100} className="swal-icon swal-icon--success">
                <span className="swal-icon--success__line swal-icon--success__line--long"></span>
                <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

                <div className="swal-icon--success__ring"></div>
                <div className="swal-icon--success__hide-corners"></div>
              </Div>
            </div>
            <div className="dialog-buttons">
              <button className="primary-button" onClick={this.redirectToCalendar} disabled={this.state.postingProgress !== 100}>Voltar para o Calendário</button>
            </div>
          </div>
        </Dialog>
        <BackdropLoading loading={!this.loaded} />
      </div>
    )
  }
}