import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router'
import CryptoJS from 'crypto-js'
import Header from '../components/utils/Header'
import SidebarMenu from '../components/utils/SidebarMenu'
import Container from '../components/utils/Container'
import Div from '../components/utils/Div'
import ClientManager, { Client } from '../managers/ClientManager'
import MediaAccountManager, { MediaAccount } from '../managers/MediaAccountManager'
import { AppContext } from '../services/AppContextService'
import { CLIENT_CATEGORIES, ROUTES, CLIENT_PLANS, CLIENT_PAYMENTS, MEDIAS_KEYS } from '../Constants'
import './CompleteAccount.scss'

import storeCategory from '../assets/images/store_category.svg'
import restaurantCategory from '../assets/images/restaurant_category.svg'
import serviceCategory from '../assets/images/service_category.svg'
import personCategory from '../assets/images/person_category.svg'
import terraIcon from '../assets/images/terra_icon.svg'
import luaIcon from '../assets/images/lua_icon.svg'
import marteIcon from '../assets/images/marte_icon.svg'
import instagram from '../assets/images/Insta_Icon.svg'
import facebook from '../assets/images/Facebook_Icon_Color.svg'

interface ICompleteAccountHeaderProps {
  children: any
  showNext?: boolean
  onClickBackButton?: () => void
  onClickNextButton?: () => void
}

interface ICompleteAccountItemProps {
  value: CompleteAccountItemValue
  currentValue?: string
  image: string
  onClick: () => void
}

interface ICompleteAccountProps {
  currentValue?: string
  onValueChange?: (value: string) => void
  onClickBackButton?: () => void
  onClickNextButton?: () => void
}

type CompleteAccountItemValue = {
  ID: string
  NAME: string
  PRICE?: string
  ITENS?: string[]
  DESCRIPTION?: string
}

function CompleteAccountHeader(props: ICompleteAccountHeaderProps) {
  return (
    <div className="complete-account-header">
      <Div className="header-arrows" active={!!props.onClickBackButton} onClick={props.onClickBackButton}>
        <i className="material-icons">arrow_back</i>
      </Div>
      <div className="header-title">{props.children}</div>
      <Div className="header-arrows" active={props.showNext} onClick={props.onClickNextButton}>
        <i className="material-icons">arrow_forward</i>
      </Div>
    </div>
  )
}

function CompleteAccountItem(props: ICompleteAccountItemProps) {
  const active = props.value.ID === props.currentValue
  const getActiveClass = () => active ? 'item-button active' : 'item-button'

  return (
    <div className="item category-item">
      <Div className="item-card" active={active} onClick={props.onClick}>
        <div className="item-cover"><img src={props.image} alt="capa da categoria" /></div>
        <div className="item-text">{props.value.DESCRIPTION}</div>
      </Div>
      <button className={getActiveClass()} onClick={props.onClick}>{props.value.NAME}</button>
    </div>
  )
}

function PlanItem(props: ICompleteAccountItemProps) {
  const active = props.value.ID === props.currentValue
  const getActiveClass = () => active ? 'item-button active' : 'item-button'

  return (
    <div className="item plan-item">
      <Div className="item-card" active={active} onClick={props.onClick}>
        <div className="item-cover">
          <div><img src={props.image} alt="icone do plano" /></div>
          <div>{props.value.NAME}</div>
        </div>
        <div className="item-text">
          {props.value.ITENS?.map((item, key) => <div key={key}><i className="material-icons">check_circle</i> {item}</div>)}
        </div>
      </Div>
      <button className={getActiveClass()} onClick={props.onClick}>{props.value.PRICE}</button>
    </div>
  )
}

function CompleteAccountPlan(props: ICompleteAccountProps) {
  const context = useContext(AppContext)
  const clientManager = new ClientManager(context)

  const setPlan = (plan: string) => clientManager.save({ plan } as Client, () => props.onValueChange!(plan))

  return (
    <div className="complete-account-content">
      <CompleteAccountHeader onClickNextButton={props.onClickNextButton} showNext={!!props.currentValue}>Escolha o plano que melhor se encaixa no seu orçamento:</CompleteAccountHeader>
      <div className="complete-account-items">
        <PlanItem image={terraIcon} value={CLIENT_PLANS.BASIC} currentValue={props.currentValue} onClick={() => setPlan(CLIENT_PLANS.BASIC.ID)} />
        <PlanItem image={luaIcon} value={CLIENT_PLANS.INTERMEDIATE} currentValue={props.currentValue} onClick={() => setPlan(CLIENT_PLANS.INTERMEDIATE.ID)} />
        <PlanItem image={marteIcon} value={CLIENT_PLANS.PRO} currentValue={props.currentValue} onClick={() => setPlan(CLIENT_PLANS.PRO.ID)} />
      </div>
    </div>
  )
}

function CompleteAccountPayment(props: ICompleteAccountProps) {
  const context = useContext(AppContext)
  const clientManager = new ClientManager(context)

  const setPayment = (payment: string) => clientManager.save({ payment } as Client, () => props.onValueChange!(payment))

  return (
    <div className="complete-account-content">
      <CompleteAccountHeader onClickBackButton={props.onClickBackButton} onClickNextButton={props.onClickNextButton} showNext={!!props.currentValue}>
        Escolha a melhor forma de pagamento:
      </CompleteAccountHeader>
      <div className="complete-account-items">
        <CompleteAccountItem image={storeCategory} value={CLIENT_PAYMENTS.CREDIT_CARD} currentValue={props.currentValue}
          onClick={() => setPayment(CLIENT_PAYMENTS.CREDIT_CARD.ID)} />
        <CompleteAccountItem image={restaurantCategory} value={CLIENT_PAYMENTS.DEBIT} currentValue={props.currentValue}
          onClick={() => setPayment(CLIENT_PAYMENTS.DEBIT.ID)} />
        <CompleteAccountItem image={serviceCategory} value={CLIENT_PAYMENTS.PAPER} currentValue={props.currentValue}
          onClick={() => setPayment(CLIENT_PAYMENTS.PAPER.ID)} />
      </div>
    </div>
  )
}

function CompleteAccountCategory(props: ICompleteAccountProps) {
  const context = useContext(AppContext)
  const clientManager = new ClientManager(context)

  const setCategory = (category: string) => clientManager.save({ category } as Client, () => props.onValueChange!(category))

  return (
    <div className="complete-account-content">
      <CompleteAccountHeader onClickBackButton={props.onClickBackButton} onClickNextButton={props.onClickNextButton} showNext={!!props.currentValue}>
        Escolha a categoria que melhor se encaixa no seu negócio:
      </CompleteAccountHeader>
      <div className="complete-account-items">
        <CompleteAccountItem image={storeCategory} value={CLIENT_CATEGORIES.STORE} currentValue={props.currentValue}
          onClick={() => setCategory(CLIENT_CATEGORIES.STORE.ID)} />
        <CompleteAccountItem image={restaurantCategory} value={CLIENT_CATEGORIES.RESTAURANT} currentValue={props.currentValue}
          onClick={() => setCategory(CLIENT_CATEGORIES.RESTAURANT.ID)} />
        <CompleteAccountItem image={serviceCategory} value={CLIENT_CATEGORIES.SERVICE} currentValue={props.currentValue}
          onClick={() => setCategory(CLIENT_CATEGORIES.SERVICE.ID)} />
        <CompleteAccountItem image={personCategory} value={CLIENT_CATEGORIES.PERSON} currentValue={props.currentValue}
          onClick={() => setCategory(CLIENT_CATEGORIES.PERSON.ID)} />
      </div>
    </div>
  )
}

function CompleteAccountMediaAccounts(props: ICompleteAccountProps) {
  const context = useContext(AppContext)

  const [facebookUsername, setFacebookUsername] = useState('')
  const [facebookPassword, setFacebookPassword] = useState('')
  const [showFacebookPassword, setShowFacebookPassword] = useState(false)

  const [instagramUsername, setInstagramUsername] = useState('')
  const [instagramPassword, setInstagramPassword] = useState('')
  const [showInstagramPassword, setShowInstagramPassword] = useState(false)

  const saveAndFinish = () => {
    const mediaAccountManager = new MediaAccountManager(context)
    const facebookPasswordHash = CryptoJS.AES.encrypt(facebookPassword, context.currentUser!.uid).toString()
    const instagramPasswordHash = CryptoJS.AES.encrypt(instagramPassword, context.currentUser!.uid).toString()

    const facebookData = { username: facebookUsername, passwordHash: facebookPasswordHash } as MediaAccount
    const instagramData = { username: instagramUsername, passwordHash: instagramPasswordHash } as MediaAccount

    if (facebookUsername && instagramUsername)
      mediaAccountManager.save(MEDIAS_KEYS.FACEBOOK_FEED, facebookData, () => {
        mediaAccountManager.save(MEDIAS_KEYS.INSTAGRAM_FEED, instagramData, props.onClickNextButton)
      })
    else if (facebookUsername)
      mediaAccountManager.save(MEDIAS_KEYS.FACEBOOK_FEED, facebookData, props.onClickNextButton)
    else
      mediaAccountManager.save(MEDIAS_KEYS.INSTAGRAM_FEED, instagramData, props.onClickNextButton)
  }

  return (
    <div className="complete-account-content">
      <CompleteAccountHeader onClickBackButton={props.onClickBackButton} onClickNextButton={saveAndFinish} showNext={!!facebookUsername || !!instagramUsername}>
        Informe os dados das suas redes sociais
      </CompleteAccountHeader>
      <div className="complete-account-items">
        <div className="item larger">
          <div className="media-list">
            <div className="media-item">
              <div className="media-data">
                <div className="text-center"><img src={facebook} alt="facebook logo" className="media-logo" /></div>
                <div>
                  <label htmlFor="inp_facebook_user" className="media-input-label">Usuário:</label>
                  <input id="inp_facebook_user" name="facebook_user" type="text" className="media-input" value={facebookUsername}
                    onChange={(event) => setFacebookUsername(event.target.value)} />
                </div>
                <div>
                  <label htmlFor="inp_facebook_password" className="media-input-label">Senha:</label>
                  <input id="inp_facebook_password" name="facebook_password" type={showFacebookPassword ? 'text' : 'password'} className="media-input"
                    value={facebookPassword} onChange={(event) => setFacebookPassword(event.target.value)} />
                  <span className="material-icons" title={showFacebookPassword ? 'Ocultar senha' : 'Mostrar senha'}
                    onClick={() => setShowFacebookPassword(!showFacebookPassword)}>
                    {showFacebookPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </div>
              </div>
            </div>

            <div className="media-item">
              <div className="media-data">
                <div className="text-center"><img src={instagram} alt="instagram logo" className="media-logo" /></div>
                <div>
                  <label htmlFor="inp_instagram_user" className="media-input-label">Usuário:</label>
                  <input id="inp_instagram_user" name="instagram_user" type="text" className="media-input" value={instagramUsername}
                    onChange={(event) => setInstagramUsername(event.target.value)} />
                </div>
                <div>
                  <label htmlFor="inp_instagram_password" className="media-input-label">Senha:</label>
                  <input id="inp_instagram_password" name="instagram_password" type={showInstagramPassword ? 'text' : 'password'} className="media-input"
                    value={instagramPassword} onChange={(event) => setInstagramPassword(event.target.value)} />
                  <span className="material-icons" title={showInstagramPassword ? 'Ocultar senha' : 'Mostrar senha'}
                    onClick={() => setShowInstagramPassword(!showInstagramPassword)}>
                    {showInstagramPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CompleteAccountFinish(props: ICompleteAccountProps) {
  const history = useHistory()
  const goToDashboard = () => history.push(ROUTES.DASHBOARD)

  return (
    <div className="complete-account-content">
      <CompleteAccountHeader onClickBackButton={props.onClickBackButton}>Concluído</CompleteAccountHeader>
      <div className="complete-account-items">
        <div className="item larger">
          <div className="item-card">Seja bem vindo a plataforma Petros. A partir de agora você poderá solicitar posts,
          revisar as postagens que estiverem prontas e acompanhar todo o calendário de postagens.</div>
          <button className="item-button" onClick={goToDashboard}>Iniciar Plataforma</button>
        </div>
      </div>
    </div>
  )
}

export default function CompleteAccount() {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentPlan, setCurrentPlan] = useState('')
  const [currentPayment, setCurrentPayment] = useState('')
  const [currentCategory, setCurrentCategory] = useState('')

  const previousStep = () => setCurrentStep(currentStep - 1)
  const nextStep = () => setCurrentStep(currentStep + 1)

  const getCurrentPage = () => {
    switch (currentStep) {
      // case 2:
      //   return <CompleteAccountPayment currentValue={currentPayment} onValueChange={setCurrentPayment} onClickBackButton={previousStep} onClickNextButton={nextStep} />
      case 2:
        return <CompleteAccountMediaAccounts onClickBackButton={previousStep} onClickNextButton={nextStep} />
      case 3:
        return <CompleteAccountFinish onClickBackButton={previousStep} />
      default:
        return <CompleteAccountCategory currentValue={currentCategory} onValueChange={setCurrentCategory} onClickBackButton={previousStep} onClickNextButton={nextStep} />
      // default:
      //   return <CompleteAccountPlan currentValue={currentPlan} onValueChange={setCurrentPlan} onClickNextButton={nextStep} />
    }
  }

  return (
    <Container>
      <SidebarMenu />
      <div className="block-page"></div>
      <Header>Conclua seu cadastro</Header>

      <div className="row">
        <main className="offset-1 col p-0 complete-account">
          <div className="complete-account-container">
            <div className="steps-control">
              <Div className="step" active={currentStep === 1}></Div>
              <Div className="step" active={currentStep === 2}></Div>
              <Div className="step" active={currentStep === 3}></Div>
              {/* <Div className="step" active={currentStep === 4}></Div> */}
              {/* <Div className="step" active={currentStep === 5}></Div> */}
            </div>
            {getCurrentPage()}
          </div>
        </main>
      </div>
    </Container>
  )
}