import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Div from './Div';
import './ProgressCircle.scss'

export interface IProgressBarProps {
  value?: number
}

export default function ProgressCircle(props: IProgressBarProps) {
  return (
    <div className="progress-circle">
      <CircularProgress variant={props.value === undefined ? 'indeterminate' : 'static'} className="circle" value={props.value} size="5vw" thickness={5} />
      <Div if={props.value !== undefined} className="label">{ props.value + '%' }</Div>
    </div>
  )
}