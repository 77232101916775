import React from 'react'
import MediaImage from '../utils/MediaImage'
import { IStepsProps, IStudioState } from '../../pages/Studio'
import Constants, { MEDIAS_LABELS } from '../../Constants'

export default function Step3(props: IStepsProps) {
  const toggleMedias = (selectedMedia: string) => {
    if (!props.state.selectedPost) {
      let selectedMedias = props.state.selectedMedias
      const foundMediaIndex = selectedMedias.findIndex(x => x === selectedMedia)

      if (foundMediaIndex > -1) {
        selectedMedias.splice(foundMediaIndex, 1)
      } else {
        selectedMedias.push(selectedMedia)
      }
      props.onChangeState({ selectedMedias, mediaRequired: false } as IStudioState)
    }
  }

  return (
    <div className="steps step-3">
      <select className="medias-validation" required={props.state.mediaRequired}></select>
      <div className="step-title">3. Mídias</div>
      <div className="accounts-list">
        {Object.keys(props.state.medias).map(key => {
          if (props.state.medias[key].username)
            return (
              <div className="account-item" key={key} onClick={() => toggleMedias(key)}>
                <MediaImage mediaKey={key} active={!!props.state.selectedMedias.find(mediaKey => key === mediaKey)} />
                <span>{Constants.getValue(MEDIAS_LABELS, key)}</span>
              </div>
            )
          return ''
        })}
      </div>
    </div>
  )
}