import React from 'react'
import { Backdrop } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ProgressCircle from './ProgressCircle'

interface IBackdropLoadingProps {
    loading: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({ backdrop: { zIndex: theme.zIndex.drawer + 1 }}))

export default function BackdropLoading(props: IBackdropLoadingProps) {
    const classes = useStyles()
    
    return <Backdrop className={classes.backdrop} open={props.loading}><ProgressCircle /></Backdrop>
}