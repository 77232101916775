import React, { useContext, useState, useEffect } from 'react'
import CryptoJS from 'crypto-js'
import swal from 'sweetalert';
import SidebarMenu from '../components/utils/SidebarMenu'
import Header from '../components/utils/Header'
import Div from '../components/utils/Div';
import BackdropLoading from '../components/utils/BackdropLoading';
import ProfilePicture from '../components/utils/ProfilePicture';
import PageControl from '../components/utils/PageControl';
import MediaAccountManager, { MediaAccount } from '../managers/MediaAccountManager'
import ClientManager from '../managers/ClientManager';
import UserManager from '../managers/UserManager';
import { AppContext } from '../services/AppContextService'
import { MENU_ITEMS, USER_PERMISSIONS, MEDIAS_KEYS } from '../Constants'
import './ListMediaAccount.scss'

import instagram from '../assets/images/Insta_Icon.svg'
import facebook from '../assets/images/Facebook_Icon_Color.svg'

class CompanyData {
  uid = ''
  companyName = ''
  profilePictureUrl = ''
}

export default function ListMediaAccount() {
  const context = useContext(AppContext)
  const pageLimit = 10
  const [loading, setLoading] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [clients, setClients] = useState([new CompanyData()])
  const [filteredClients, setFilteredClients] = useState([new CompanyData()])
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedClient, setSelectedClient] = useState(new CompanyData())
  const [mediaAccountManager, setMediaAccountManager] = useState(new MediaAccountManager(context))

  const [facebookUsername, setFacebookUsername] = useState('')
  const [facebookPassword, setFacebookPassword] = useState('')
  const [showFacebookPassword, setShowFacebookPassword] = useState(false)

  const [instagramUsername, setInstagramUsername] = useState('')
  const [instagramPassword, setInstagramPassword] = useState('')
  const [showInstagramPassword, setShowInstagramPassword] = useState(false)

  const checkPermissons = () => {
    if (context.currentUser) {
      setIsClient(context.userPermission === USER_PERMISSIONS.CLIENT)
      setIsAdmin(context.userPermission === USER_PERMISSIONS.ADMIN)
    }
  }

  const loadAccounts = () => {
    if (selectedClient.uid || isClient) {
      setLoading(true)
      const manager = new MediaAccountManager(context, selectedClient.uid)
      const uid = selectedClient.uid || context.currentUser!.uid
      setMediaAccountManager(manager)
      manager.getAll(medias => {
        const facebookData = medias[MEDIAS_KEYS.FACEBOOK_FEED]
        const instagramData = medias[MEDIAS_KEYS.INSTAGRAM_FEED]

        setFacebookUsername(facebookData.username)
        setInstagramUsername(instagramData.username)

        if (facebookData.passwordHash) {
          const bytes = CryptoJS.AES.decrypt(facebookData.passwordHash, uid)
          const password = bytes.toString(CryptoJS.enc.Utf8)
          setFacebookPassword(password)
        }
        if (instagramData.passwordHash) {
          const bytes = CryptoJS.AES.decrypt(instagramData.passwordHash, uid)
          const password = bytes.toString(CryptoJS.enc.Utf8)
          setInstagramPassword(password)
        }
        setLoading(false)
      })
    }
  }

  const loadClients = () => {
    if (isAdmin) {
      setLoading(true)
      ClientManager.getAll(clientList => {
        const list = []
        for (let uid in clientList) {
          list.push({ uid, companyName: clientList[uid].companyName } as CompanyData)
        }
        setClients(list)
      })
    }
  }

  const saveData = (mediaId: string, username: string, password: string) => {
    const uid = selectedClient.uid || context.currentUser!.uid
    const passwordHash = CryptoJS.AES.encrypt(password, uid).toString()
    const data = { username, passwordHash } as MediaAccount
    mediaAccountManager.save(mediaId, data, openSavedDialog)
  }

  const saveFacebookData = () => saveData(MEDIAS_KEYS.FACEBOOK_FEED, facebookUsername, facebookPassword)

  const saveInstagramData = () => saveData(MEDIAS_KEYS.INSTAGRAM_FEED, instagramUsername, instagramPassword)

  const openSavedDialog = () => swal('Suas alterações foram salvas com sucesso!', '', 'success')

  const buildFilteredClients = () => {
    if (isAdmin) {
      const filteredList = clients.slice(currentPage * pageLimit, currentPage + pageLimit)
      const promises = [];

      for (let item of filteredList) {
        promises.push(new Promise((resolve, reject) => {
          UserManager.get(item.uid, user => {
            if (user.profilePicture) {
              UserManager.getUserPicture(user.profilePicture.fullPath, url => {
                item.profilePictureUrl = url
                resolve()
              })
            } else {
              resolve()
            }
          })
        }))
      }

      Promise.all(promises).then(() => {
        setFilteredClients(filteredList)
        setLoading(false)
      })
    }
  }

  useEffect(checkPermissons, [context.currentUser])
  useEffect(loadAccounts, [selectedClient, isClient])
  useEffect(loadClients, [isAdmin])
  useEffect(buildFilteredClients, [clients, currentPage])

  return (
    <div className="container-fluid p-0">
      <SidebarMenu activeMenu={MENU_ITEMS.MEDIA_ACCOUNTS} />
      <Header>Contas</Header>

      <Div if={isClient || !!selectedClient?.uid} className="row list-media-account">
        <main className="offset-1 p-0 col-10">
          <div className="header-title">Gerencie aqui todos os usuários e senhas das suas redes de forma segura</div>
          <div className="media-list">
            <Div if={!!selectedClient.companyName} className="company-name">{selectedClient.companyName}</Div>
            <div className="media-item">
              <div className="media-data">
                <div className="text-center"><img src={facebook} alt="facebook logo" className="media-logo" /></div>
                <div>
                  <label htmlFor="inp_facebook_user" className="media-input-label">Usuário:</label>
                  <input id="inp_facebook_user" name="facebook_user" type="text" className="media-input" value={facebookUsername}
                    onChange={(event) => setFacebookUsername(event.target.value)} />
                </div>
                <div>
                  <label htmlFor="inp_facebook_password" className="media-input-label">Senha:</label>
                  <input id="inp_facebook_password" name="facebook_password" type={showFacebookPassword ? 'text' : 'password'} className="media-input"
                    value={facebookPassword} onChange={(event) => setFacebookPassword(event.target.value)} />
                  <span className="material-icons" title={showFacebookPassword ? 'Ocultar senha' : 'Mostrar senha'}
                    onClick={() => setShowFacebookPassword(!showFacebookPassword)}>
                    {showFacebookPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </div>
              </div>
              <div className="media-buttons">
                <button className="primary-button" onClick={saveFacebookData}>Salvar</button>
              </div>
            </div>

            <div className="media-item">
              <div className="media-data">
                <div className="text-center"><img src={instagram} alt="instagram logo" className="media-logo" /></div>
                <div>
                  <label htmlFor="inp_instagram_user" className="media-input-label">Usuário:</label>
                  <input id="inp_instagram_user" name="instagram_user" type="text" className="media-input" value={instagramUsername}
                    onChange={(event) => setInstagramUsername(event.target.value)} />
                </div>
                <div>
                  <label htmlFor="inp_instagram_password" className="media-input-label">Senha:</label>
                  <input id="inp_instagram_password" name="instagram_password" type={showInstagramPassword ? 'text' : 'password'} className="media-input"
                    value={instagramPassword} onChange={(event) => setInstagramPassword(event.target.value)} />
                  <span className="material-icons" title={showInstagramPassword ? 'Ocultar senha' : 'Mostrar senha'}
                    onClick={() => setShowInstagramPassword(!showInstagramPassword)}>
                    {showInstagramPassword ? 'visibility' : 'visibility_off'}
                  </span>
                </div>
              </div>
              <div className="media-buttons">
                <button className="primary-button" onClick={saveInstagramData}>Salvar</button>
              </div>
            </div>
          </div>
          <div className="back-button">
            <button className="primary-button" onClick={() => setSelectedClient(new CompanyData())}>Voltar</button>
          </div>
        </main>
      </Div>

      <Div if={isAdmin && !selectedClient?.uid} className="row list-media-account">
        <main className="offset-1 p-0 col-10">
          <div className="header-title">Edite aqui as informações dos clientes cadastrados</div>
          <div className="client-selection">
            <PageControl pageLimit={pageLimit} listLength={clients.length} currentPage={currentPage} onPageChange={setCurrentPage} />
            <div className="client-list">
              {filteredClients.map(client =>
                <div key={client.uid} className="client-item" onClick={() => setSelectedClient(client)}>
                  <ProfilePicture profilePictureUrl={client.profilePictureUrl} />
                  <div className="company-name">{client.companyName}</div>
                </div>
              )}
            </div>
          </div>
        </main>
      </Div>
      <BackdropLoading loading={loading} />
    </div >
  )
}