import FirebaseService from './FirebaseService'
import ErrorService from './ErrorService'

export type UploadFile = {
  name: string
  fullPath: string
  contentType: string
}

type StorageReference = FirebaseService.storage.Reference
type UploadTask = FirebaseService.storage.UploadTask
type UploadTaskSnapshot = FirebaseService.storage.UploadTaskSnapshot
type ListResult = FirebaseService.storage.ListResult

const FirebaseStorage = FirebaseService.storage()
const TaskEvents = FirebaseService.storage.TaskEvent

const createRef = (...stringRef: string[]) => FirebaseStorage.ref(stringRef.join('/'))

const uploadFile = (ref: StorageReference, file: File) => ref.put(file)

const uploadBase64 = (ref: StorageReference, base64: string) => ref.putString(base64, 'base64')

const uploadBase64Url = (ref: StorageReference, base64Url: string) => ref.putString(base64Url, 'base64url')

const uploadDataUrl = (ref: StorageReference, dataUrl: string) => ref.putString(dataUrl, 'data_url')

const createUploadListen =
  (uploadTask: UploadTask, onProgress: (progress: number) => void, onError: (error: any) => void, onFinish: (fullPath: UploadFile) => void) =>
    uploadTask.on(TaskEvents.STATE_CHANGED,
      (snapshot: UploadTaskSnapshot) => onProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      onError,
      () => onFinish({
        name: uploadTask.snapshot.metadata.name,
        fullPath: uploadTask.snapshot.metadata.fullPath,
        contentType: uploadTask.snapshot.metadata.contentType!
      })
    )

const getDownloadUrl = (ref: StorageReference, successfulCallback: (url: string) => void, failCallback?: (error: any) => void) =>
  ref.getDownloadURL()
    .then(successfulCallback)
    .catch(failCallback ? failCallback : ErrorService.setError)

const removeFile = (ref: StorageReference, successfulCallback: () => void, failCallback?: (error: any) => void) =>
  ref.delete()
    .then(successfulCallback)
    .catch(failCallback ? failCallback : ErrorService.setError)

const listAll = (ref: StorageReference, successfulCallback: (result: ListResult) => void, failCallback?: (error: any) => void) =>
  ref.listAll()
    .then(successfulCallback)
    .catch(failCallback)

const removeAll = (ref: StorageReference, successfulCallback: () => void, failCallback?: (error: any) => void) => {
  listAll(ref, result => {
    const promises = []
    for (let file of result.items) {
      promises.push(new Promise((resolve, reject) => file.delete().then(resolve).catch(reject)))
    }
    Promise.all(promises)
      .then(successfulCallback)
      .catch(failCallback)
  })
}

export default { createRef, uploadFile, uploadBase64, uploadBase64Url, uploadDataUrl, createUploadListen, getDownloadUrl, removeFile, listAll, removeAll }