export const MENU_ITEMS = {
  DASHBOARD: 'Dashboard',
  CALENDAR: 'Calendário',
  STUDIO: 'Estúdio',
  MEDIA_ACCOUNTS: 'Contas',
  SETTINGS: 'Configurações',
  PROFILE: 'Perfil'
}

export const ROUTES = {
  CALENDAR: '/calendario',
  COMPLETE_ACCOUNT: '/concluir-cadastro',
  CREATE_ACCOUNT: '/criar-conta',
  DASHBOARD: '/',
  ERROR: '/erro',
  LOGIN: '/login',
  MEDIA_ACCOUNT: '/contas',
  SETTINGS: '/configuracoes',
  STUDIO: '/estudio',
  PROFILE: '/perfil'
}

export const DATABASE_REFS = {
  CLIENTS: '/clients',
  USERS: '/users',
  POSTS: '/posts',
  ACCOUNTS: '/media-accounts',
  PROFILE_PICTURES: '/profile_pictures',
  TEMPORARY_USERS: '/temporary-users'
}

export const LOGIN_METHODS = {
  EMAIL: 'EMAIL',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK'
}

export const USER_PERMISSIONS = {
  ADMIN: 'ADMIN',
  SPECIALIST: 'SPECIALIST',
  CLIENT: 'CLIENT'
}

export const LOCAL_STORAGE_KEYS = {
  LOGGED: 'LOGGED',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  SELECTED_CLIENT: 'SELECTED_CLIENT'
}

export const CLIENT_PLANS = {
  BASIC: {
    ID: 'BASIC',
    NAME: 'ESTAÇÃO ESPACIAL (BÁSICO)',
    PRICE: 'R$ 450,00',
    ITENS: [
      '2 posts semanais',
      '2 stories semanais',
      '1 posts patrocinados',
      'Estratégia de resultados',
      'Planejamento de mídias',
      'Relatórios mensais'
    ]
  },
  INTERMEDIATE: {
    ID: 'INTERMEDIATE',
    NAME: 'BASE LUNAR (INTERMEDIÁRIO)',
    PRICE: 'R$ 800,00',
    ITENS: [
      '3 posts semanais',
      '3 stories semanais',
      '4 posts patrocinados',
      'Estratégia de resultados',
      'Planejamento de campanha',
      'Relatório mensal',
      'Marketing de influência'
    ]
  },
  PRO: {
    ID: 'PRO',
    NAME: 'COLÔNIA MARCIANA (PRO)',
    PRICE: 'R$ 2.500,00',
    ITENS: [
      '5 posts semanais',
      '5 stories semanais',
      '10 posts patrocinados',
      '1 campanha',
      '2 Vídeos (motion)',
      'Relatório Mensal',
      'Marketing de influência',
      'Assessoria de mídia offline',
      'Gerenciamento diário'
    ]
  }
}

export const CLIENT_PAYMENTS = {
  CREDIT_CARD: {
    ID: 'CREDIT_CARD',
    NAME: 'Cartão de Crédito',
    DESCRIPTION: 'EXEMPLO - CONDIÇÕES REFERENTE A ESCOLHA DO CARTÃO DE CRÉDITO.'
  },
  DEBIT: {
    ID: 'DEBIT',
    NAME: 'Débito em Conta',
    DESCRIPTION: 'EXEMPLO - CONDIÇÕES REFERENTE A ESCOLHA DO DÉBITO EM CONTA.'
  },
  PAPER: {
    ID: 'PAPER',
    NAME: 'Boleto',
    DESCRIPTION: 'EXEMPLO - CONDIÇÕES REFERENTE A ESCOLHA DO PAGAMENTO EM BOLETO.'
  }
}

export const CLIENT_CATEGORIES = {
  STORE: {
    ID: 'STORE',
    NAME: 'Loja',
    DESCRIPTION: 'EXEMPLO - SUPERMERCADO, LOJA DE MÓVEIS, LOJA DE ELETRÔNICOS, PAPELARIA, LOJA DE ROUPAS, CONSTRUÇÃO, PEÇAS DE CARRO OU EQUIVALENTES.'
  },
  RESTAURANT: {
    ID: 'RESTAURANT',
    NAME: 'Restaurante',
    DESCRIPTION: 'EXEMPLO - PIZZARIA, COZINHA ORIENTAL, COZINHA ITALIANA, LANCHONETE, HAMBURGUERIA, BAR OU EQUIVALENTES.'
  },
  SERVICE: {
    ID: 'SERVICE',
    NAME: 'Serviço',
    DESCRIPTION: 'EXEMPLO - LAVANDERIA, LIMPEZA, OFICINA DE CARROS, SEGURADORA, LOCADORA DE VEÍCULOS, INSTITUIÇÕES FINANCEIRAS OU EQUIVALENTES.'
  },
  PERSON: {
    ID: 'PERSON',
    NAME: 'Pessoa',
    DESCRIPTION: 'EXEMPLO - POLÍTICO, ARTISTA, PALESTRANTE, BLOGUEIRA, PERSONAL TRAINER, PROFISSIONAL DA SAÚDE, EMPRESÁRIO OU EQUIVALENTES.'
  }
}

export const LOGIN_ERRORS = {
  'auth/wrong-password': 'SENHA INCORRETA',
  'auth/user-not-found': 'EMAIL INCORRETO',
  'user-blocked': 'USUÁRIO BLOQUEADO',
  INTERN_ERROR: 'ERRO INTERNO',
  AUTH_ERROR: 'FALHA NA AUTENTICAÇÃO',
  EMAIL_REQUIRED: 'EMAIL OBRIGATÓRIO',
  PASSWORD_REQUIRED: 'SENHA OBRIGATÓRIA'
}

export const EXCEPTION_ERRORS = {
  UNKNOW: 'Ocorreu um erro ao tentar realizar essa ação, tente novamente mais tarde.',
  SAVE_USER: 'Ocorreu um erro ao tentar salvar o usuário, tente novamente mais tarde.',
  SAVE_CLIENT: 'Ocorreu um erro ao tentar salvar os dados, tente novamente mais tarde.',
  'auth/email-already-in-use': 'O e-mail informado já está em uso por outro usuário.',
  'auth/invalid-email': 'E-mail no formato inválido, corrija seu e-mail e tente novamente.',
  'auth/requires-recent-login': 'Esta operação exige que você faça o login novamente.',
  'auth/wrong-password': 'Senha informada está incorreta',
}

export const MONTHS = {
  0: 'JANEIRO',
  1: 'FEVEREIRO',
  2: 'MARÇO',
  3: 'ABRIL',
  4: 'MAIO',
  5: 'JUNHO',
  6: 'JULHO',
  7: 'AGOSTO',
  8: 'SETEMBRO',
  9: 'OUTUBRO',
  10: 'NOVEMBRO',
  11: 'DEZEMBRO',
}

export const DAYS_NAMES = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB']

export const POST_STATUS = {
  REQUESTED: 'REQUESTED',
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  PUBLISHED: 'PUBLISHED'
}

export const POST_STATUS_LABELS = {
  REQUESTED: 'Solicitado pelo cliente',
  REVIEW: 'Aguardando revisão do cliente',
  APPROVED: 'Aprovado pelo cliente',
  DENIED: 'Não aceito pelo cliente',
  PUBLISHED: 'Publicado'
}

export const MEDIAS_KEYS = {
  INSTAGRAM_FEED: 'INSTAGRAM_FEED',
  INSTAGRAM_STORIES: 'INSTAGRAM_STORIES',
  INSTAGRAM_IGTV: 'INSTAGRAM_IGTV',
  FACEBOOK_FEED: 'FACEBOOK_FEED'
}

export const MEDIAS_LABELS = {
  INSTAGRAM_FEED: 'Instagram - Feed',
  INSTAGRAM_STORIES: 'Instagram - Stories',
  INSTAGRAM_IGTV: 'Instagram - IGTV',
  FACEBOOK_FEED: 'Facebook - Feed'
}

export const FILE_TYPES = {
  JPG: 'image/jpeg',
  PNG: 'image/png',
  MP4: 'video/mp4'
}

export const MASKS = {
  COMPANY_ID: {
    CPF: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    CNPJ: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  },
  PHONE: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  CELLPHONE: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}

export const SETTINGS_COMPONENTS = {
  TIME_PETROS: 'TIME_PETROS',
  ADMIN: 'ADMIN'
}

const getValue = (constant: Object, key: string = '') => Object.getOwnPropertyDescriptor(constant, key)?.value
const getErrorMessage = (key: string) => (Object.getOwnPropertyDescriptor(EXCEPTION_ERRORS, key)?.value) || EXCEPTION_ERRORS.UNKNOW

export default { getValue, getErrorMessage }