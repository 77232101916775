import React from 'react'
import { Badge, withStyles, createStyles, MenuItem, Select } from '@material-ui/core'
import Div from './Div'
import ClientManager from '../../managers/ClientManager'
import { IPendingClient } from '../../managers/PostManager'
import { AppContext } from '../../services/AppContextService'
import './ClientSelect.scss'

interface IClientSelectProps {
  if?: boolean
  selectedClient: string
  onSelectClient: (client: string) => void
  pendingClients?: IPendingClient
}

interface IClientSelectState {
  clients: { uid: string, companyName: string }[]
  selectedClient: string
}

const StyledBadge = withStyles(() => createStyles({ root: { marginLeft: '1vw' } }))(Badge)

export default class ClientSelect extends React.Component<IClientSelectProps, IClientSelectState> {
  static contextType = AppContext
  loaded = false

  constructor(props: IClientSelectProps) {
    super(props)

    this.state = {
      clients: [],
      selectedClient: ''
    }
  }

  componentDidMount() {
    if ((this.props.if || this.props.if === undefined) && this.context.currentUser) {
      this.loadClients()
      this.loaded = true
    }
  }

  componentDidUpdate(prevProps: IClientSelectProps) {
    if (this.props.if || this.props.if === undefined) {
      if (this.state.clients.length) {
        if (prevProps.selectedClient !== this.props.selectedClient) {
          this.setState({ selectedClient: this.props.selectedClient })
        }
      } else if (!this.loaded && this.context.currentUser) {
        this.loadClients()
      }
    }
  }

  loadClients() {
    ClientManager.getAll(clientList => {
      const clients = []
      for (let uid in clientList) {
        clients.push({ uid, companyName: clientList[uid].companyName })
      }
      this.setState({ clients, selectedClient: this.props.selectedClient })
    })
  }

  getPendingCount = (client: string) => {
    if (this.props.pendingClients) {
      const posts = this.props.pendingClients[client]
      if (posts && posts.length > 0)
        return <StyledBadge badgeContent={posts.length} color="error" />
    }
    return ''
  }

  render() {
    return (
      <Div if={this.props.if} className="client-select">
        <div className="client-label">Seleção de cliente</div>
        <div className="client-list">
          <Select
            value={this.state.selectedClient}
            onChange={ev => this.props.onSelectClient(ev.target.value as string)}
            disableUnderline={true}
            required={true}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}>
            {this.state.clients.map(client => <MenuItem key={client.uid} value={client.uid}>{client.companyName} {this.getPendingCount(client.uid)}</MenuItem>)}
          </Select>
        </div>
      </Div>
    )
  }
}