import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Style } from '@material-ui/icons';
import BackdropLoading from './BackdropLoading'
import Div from './Div'
import { AppContext } from '../../services/AppContextService'
import { MENU_ITEMS, ROUTES, USER_PERMISSIONS } from '../../Constants'
import './SidebarMenu.scss'

export interface ISidebarMenu {
  activeMenu?: string
}

export default function SidebarMenu(props: ISidebarMenu) {
  const context = useContext(AppContext)
  const defaultProfilePicture = <i className="material-icons">account_circle</i>
  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture)

  const getClassesNames = (item: string) => {
    return props.activeMenu === item ? 'sidebar-item active' : 'sidebar-item'
  }

  const getMenuText = (item: string) => {
    return props.activeMenu === item ? item : ''
  }

  const getUserPicture = () => {
    if (context.profilePictureUrl) {
      const image = new Image()
      image.onload = () => {
        const picture = <div className="profile-picture" style={{ backgroundImage: `url('${image.src}')` }}></div>
        setProfilePicture(picture)
      }
      image.src = context.profilePictureUrl
    }
  }

  useEffect(getUserPicture, [context.profilePictureUrl])

  return (
    <div className="sidebar-menu">
      <Link to={ROUTES.PROFILE} className={getClassesNames(MENU_ITEMS.PROFILE)} title={MENU_ITEMS.PROFILE}>
        {profilePicture}
        <span>{getMenuText(MENU_ITEMS.PROFILE)}</span>
      </Link>
      {/* <Link to={ROUTES.DASHBOARD} className={getClassesNames(MENU_ITEMS.DASHBOARD)}>
        <i className="material-icons">home</i>
        <span>{getMenuText(MENU_ITEMS.DASHBOARD)}</span>
      </Link> */}
      <Link to={ROUTES.CALENDAR} className={getClassesNames(MENU_ITEMS.CALENDAR)} title={MENU_ITEMS.CALENDAR}>
        <i className="material-icons">calendar_today</i>
        <span>{getMenuText(MENU_ITEMS.CALENDAR)}</span>
      </Link>
      <Link to={ROUTES.STUDIO} className={getClassesNames(MENU_ITEMS.STUDIO)} title={MENU_ITEMS.STUDIO}>
        <div className="icon"><Style style={{ fontSize: '2.500vw' }} /></div>
        <span>{getMenuText(MENU_ITEMS.STUDIO)}</span>
      </Link>
      <Div if={context.userPermission !== USER_PERMISSIONS.SPECIALIST}>
        <Link to={ROUTES.MEDIA_ACCOUNT} className={getClassesNames(MENU_ITEMS.MEDIA_ACCOUNTS)} title={MENU_ITEMS.MEDIA_ACCOUNTS}>
          <i className="material-icons">supervisor_account</i>
          <span>{getMenuText(MENU_ITEMS.MEDIA_ACCOUNTS)}</span>
        </Link>
      </Div>
      <Div if={context.userPermission === USER_PERMISSIONS.ADMIN}>
        <Link to={ROUTES.SETTINGS} className={getClassesNames(MENU_ITEMS.SETTINGS)} title={MENU_ITEMS.SETTINGS}>
          <i className="material-icons">settings</i>
          <span className="large-text">{getMenuText(MENU_ITEMS.SETTINGS)}</span>
        </Link>
      </Div>
      <BackdropLoading loading={!context.currentUser} />
    </div>
  )
}